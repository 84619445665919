export const mspActions = {
  CLICK_MSP_BILLING_INFO_CREATE: "CLICK_MSP_BILLING_INFO_CREATE",
  CLICK_MSP_BILLING_INFO_DELETE: "CLICK_MSP_BILLING_INFO_DELETE",
  CLICK_MSP_BILLING_RULE_CREATE_FORM_OPENED:
    "CLICK_MSP_BILLING_RULE_CREATE_FORM_OPENED",
  CLICK_MSP_BILLING_RULE_CREATE: "CLICK_MSP_BILLING_RULE_CREATE",
  CLICK_MSP_SHARED_PAYER_INTEGRATIONS_CREATE:
    "CLICK_MSP_SHARED_PAYER_INTEGRATIONS_CREATE",
  CLICK_MSP_SHARED_PAYER_INTEGRATIONS_UPDATE:
    "CLICK_MSP_SHARED_PAYER_INTEGRATIONS_UPDATE",
  SELECT_MSP_ALERT_TO_INVESTIGATE_IN_TRE:
    "SELECT_MSP_ALERT_TO_INVESTIGATE_IN_TRE",
  SELECT_MSP_BILLING_INFO: "SELECT_MSP_BILLING_INFO",
  SELECT_MSP_BILLING_RULE_FILTER_BY_TENANT:
    "SELECT_MSP_BILLING_RULE_FILTER_BY_TENANT",
  SELECT_MSP_DASHBOARD_CLOUD_PROVIDER_FILTER:
    "SELECT_MSP_DASHBOARD_CLOUD_PROVIDER_FILTER",
  SELECT_MSP_DASHBOARD_CLOUD_STATUS_FILTER:
    "SELECT_MSP_DASHBOARD_CLOUD_STATUS_FILTER",
  SELECT_MSP_DASHBOARD_TENANT_FILTER: "SELECT_MSP_DASHBOARD_TENANT_FILTER",
  SELECT_MSP_SUBACCOUNT_TO_INVESTIGATE_IN_MANAGE_TENANT:
    "SELECT_MSP_SUBACCOUNT_TO_INVESTIGATE_IN_MANAGE_TENANT",
  SELECT_MSP_SUBACCOUNTS_INTIGRATIONS_FILTER:
    "SELECT_MSP_SUBACCOUNTS_INTIGRATIONS_FILTER",
  SELECT_MSP_SUBACCOUNTS_PROVIDER_FILTER:
    "SELECT_MSP_SUBACCOUNTS_PROVIDER_FILTER",
  SELECT_MSP_SUBACCOUNTS_REVIEW_INCLUDE_BIGQUERY:
    "SELECT_MSP_SUBACCOUNTS_REVIEW_INCLUDE_BIGQUERY",
} as const;
