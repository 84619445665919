import ErrorBoundary from "@/components/ErrorBoundary";
import useGatekeeper from "@/hooks/useGatekeeper";
import Tabs from "@/ui-lib/components/Tabs";
import { useTheme } from "@emotion/react";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import externalLinks from "../../../constants/externalLinks";
import copyText from "../copyText";
import ReallocationManagementContainer from "./ReallocationManagementContainer";

export function ReallocationPage(): JSX.Element {
  const [searchParamState, setSearchParamState] = useQueryParams({
    tab: withDefault(StringParam, "allocationRules"),
  });

  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  const containers = [
    {
      component: <ReallocationManagementContainer />,
      disabled: !gatekeeper.canListReallocations,
      label: copyText.reallocationTabLabelAllocationRules,
      value: "allocationRules",
    },
  ];

  return (
    <ErrorBoundary boundaryName="ReallocationPage">
      <Box marginBottom={theme.space_lg}>
        <Text>
          {copyText.learnMoreCaption + " "}
          <a
            href={externalLinks.readmeCostAllocationDocumentation}
            rel="noreferrer"
            target="_blank"
          >
            {copyText.learnMoreLink}
          </a>
        </Text>
      </Box>

      <Tabs
        activeValue={searchParamState.tab}
        tabs={containers}
        onSelect={(tab: string) => setSearchParamState({ tab })}
      />
    </ErrorBoundary>
  );
}

export default ReallocationPage;
