import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { UpdateMspSharedPayerIntegrationsParams } from "@/api/core/types";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";

type Options = UseMutationOptions<
  string[],
  CoreAPIClientError,
  UpdateMspSharedPayerIntegrationsParams
>;

type Result = UseMutationResult<
  string[],
  CoreAPIClientError,
  UpdateMspSharedPayerIntegrationsParams
>;

export default function useUpdateMspSharedPayerIntegrations(
  options?: Options
): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: (params) => client.updateMspSharedPayerIntegrations(params),
    ...options,
  });
}
