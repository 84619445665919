import paths from "@/constants/paths";
import useGatekeeper from "@/hooks/useGatekeeper";
import useGetTenantByID from "@/hooks/useGetTenantByID";
import useUpdateTenant from "@/hooks/useUpdateTenant";
import { useMspStore } from "@/lib/zustand";
import { AlertType, postAlert } from "@/utils/alerts";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import React from "react";
import { useParams } from "react-router-dom";
import copyText from "../copyText";
import { useCreateTenant } from "../hooks/useCreateTenant";
import TenantForm from "./TenantForm";

type Interaction = TenantForm.Interaction;

export default function MspChildTenantDetailsContainer(): JSX.Element {
  const gatekeeper = useGatekeeper();
  const { tenantID } = useParams();
  const mspStore = useMspStore();

  //
  // Queries
  //

  const isMspAdminLocation = location.pathname.startsWith(paths._mspMgmt);

  const {
    data: tenant,
    isLoading: isLoadingTenant,
    refetch: refetchTenant,
  } = useGetTenantByID(tenantID ?? "", {
    enabled:
      (gatekeeper.canReadTenantsSystem || gatekeeper.canReadTenantsPartner) &&
      !!tenantID,
  });

  //
  // Mutations
  //

  const { isPending: isCreatingTenant, mutate: createTenant } = useCreateTenant(
    {
      onError: () => {
        postAlert({
          type: AlertType.ERROR,
          message: copyText.errorCreatingTenantMessage,
        });
      },
      onSuccess: () => {
        refetchTenant();

        postAlert({
          type: AlertType.SUCCESS,
          message: copyText.successCreatingTenantMessage,
        });
      },
    }
  );

  const { isPending: isUpdatingTenant, mutate: updateTenant } = useUpdateTenant(
    {
      onError: () => {
        postAlert({
          type: AlertType.ERROR,
          message: copyText.errorUpdatingTenantMessage,
        });
      },
      onSuccess: () => {
        refetchTenant();

        postAlert({
          type: AlertType.SUCCESS,
          message: copyText.successUpdatingTenantMessage,
        });
      },
    }
  );

  //
  // Interaction Handlers
  //

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case TenantForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE: {
        createTenant({
          ...(mspStore.selectedParentTenantID && isMspAdminLocation
            ? { parentTenantID: mspStore.selectedParentTenantID }
            : {}),
          currencyCode: "USD",
          name: interaction.name,
          status: interaction.status,
          type: interaction.tenantType,
        });
        return;
      }

      case TenantForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE: {
        updateTenant({
          tenantID: interaction.tenantID,
          name: interaction.name,
          status: interaction.status,
        });
        return;
      }
    }
  }

  if (isLoadingTenant) {
    return <EmptyPlaceholder loading />;
  }

  return (
    <Flex direction="column">
      <TenantForm
        isInternalMode={!isMspAdminLocation}
        isProcessing={isUpdatingTenant || isCreatingTenant}
        selectedTenant={tenant}
        onInteraction={handleInteraction}
      />
    </Flex>
  );
}
