import copyText from "../../common.copyText";

/* prettier-ignore */
export default {
  ...copyText,
  actionButtonLable: "Actions",
  actionDropdownOptionLabelAccessTenant: "Access Tenant",
  actionDropdownOptionLabelBillingInformation: "%ACTION% Billing Information",
  actionDropdownOptionLabelEditTenant: "Manage Tenant",
  actionDropdownOptionLabelExportInvoiceManifest: "Export Invoice Manifest",
  actionDropdownOptionLabelManageFeatureFlags: "Manage Feature Flags",
  actionDropdownOptionLabelViewTenants: "View Tenants",
  actionDropdownOptionLabelViewUsers: "View Users",
  addFeatureFlagButtonLabel: "Add Feature Flag",
  addUsersButtonLabel: "Add Users",
  auditLogModalTitle: "Audit Log",
  backToTenantsButtonLabel: "Back To All Tenants",
  billingInfoAccountIDLabel: "Account ID",
  billingInfoAddressLine1Label: "Address Line 1",
  billingInfoAddressLine2Label: "Address Line 2",
  billingInfoBillingIDLabel: "Billing ID",
  billingInfoCityLabel: "City",
  billingInfoModalRemoveButtonLabel: "Remove",
  billingInfoModalTitle: "billing information",
  billingInfoPaymentTermLabel: "Payment Term",
  billingInfoStateLabel: "State",
  billingInfoZipcodeLabel: "Zip Code/Postcode",
  createTenantButtonLabel: "Create Tenant",
  dashboardsTabLabel: "Dashboards",
  deactivateTenantConfirmationMessage: "Deactivating this tenant will cause the following: \n- All data ingestion will stop \n- Users will be unable to access the platform \n- Notifications will no longer be sent \n\nAre you sure you want to do this?",
  deactivateTenantConfirmationTitle: "Deactivate Tenant",
  deleteBillingInfoConfirmationMessage: "Removing billing information for this tenant is permanent. Are you sure you want to do this?",
  deleteBillingInfoConfirmationTitle: "Remove Billing Information",
  detailsTabLabel: "Details",
  errorAddingFeatureFlagsMessage: "An error occurred while attempting to add the feature flag. Please try again.",
  errorCreateingMspSharedPayerIntegrationsMessage: "An error occurred while attempting to create Subaccount integrations. Please try again.",
  errorCreatingBillingInformationMessage: "An error occured when attempting to create the billing information. Please try again.", 
  errorCreatingManifestMessage: "An error occured when attempting to create the manifest. Please try again.", 
  errorCreatingMspBillingInvoiceSettingsMessage: "An error occured when attempting to create the invoice settings. Please try again.", 
  errorCreatingTenantMessage: "An error occured when attempting to create the tenant. Please try again.", 
  errorGrantingUsersGlobalRolesMessage: "An error occured when attempting to give users global roles. Please try again.",
  errorGrantingUserTenantAccessMessage: "An error occured when attempting to grant access to the tenant. Please try again.",
  errorLoadingMspBillingInvoicePDFMessage: "There was an error loading the selected PDF. Please try again.",
  errorRemovingFeatureFlagsMessage: "An error occurred while attempting to remove the feature flag. Please try again.",
  errorRemovingMspBillingInfoMessage: "An error occurred while attempting to remove billing information. Please try again.",
  errorRevokingUserGlobalRoleMessage: "An error occurred while attempting to revoke user global roles. Please try again.",
  errorUpdateingMspSharedPayerIntegrationsMessage: "An error occurred while attempting to update Subaccount integrations. Please try again.",
  errorUpdatingBillingInfoMessage: "An error occurred while attempting to update the billing information. Please try again.",
  errorUpdatingMspBillingInvoiceSettings: "An error occurred while attempting to update the invoice settings. Please try again.",
  errorUpdatingTenantMessage: "An error occurred while attempting to update the tenant. Please try again.",
  errorUpdatingUserTenantRolesMessage: "An error occurred while attempting to update the users roles. Please try again.",
  featureFlagOptionLabel_ALLOW_TLOS_SLOW_PATH: "Allow TLOS Slow Path",
  featureFlagOptionLabel_BYPASS_TLOS_SLOW_PATH: "Bypass TLOS Slow Path",
  featureFlagOptionLabel_COST_COMPARE: "Cost Compare",
  featureFlagOptionLabel_CUSTOM_PRICING: "Custom Pricing",
  featureFlagOptionLabel_FOCUS_DATASETS: "Focus Datasets",
  featureFlagOptionLabel_MSP_INVOICING: "MSP Invoicing",
  featureFlagsModalTitle: "Feature Flags",
  featureFlagTableHeaderCreatedAt: "Created At",
  featureFlagTableHeaderCreatedBy: "Created By",
  featureFlagTableHeaderFlag: "Flag",
  filterStatusPlaceholderText: "Filter Status",
  filterTenantsPlaceholderText: "Filter Tenants",
  formTitleCreate: "Create New Tenant",
  formTitleInformation: "Tenant Information",
  formTitleUpdate: "Update Tenant",
  globalRolesTabLabel: "Global Roles",
  goBackButtonLabel: "Go Back",
  groupedLastRefreshTooltip: "This date represents the oldest data refresh.",
  integrationsIncludeBigQueryLabel: "Included BigQuery data",
  integrationsSelectedLabel: "Selected",
  integrationsTabLabel: "Integrations",
  lastRefresh: "%VALUE% ago",
  manifestListModalExportManifest: 'Export Manifest',
  manifestListModalExportPdf: 'Export PDF',
  manifestListModalNewLabel: "New",
  modalTitleCreateManifest: "Create Manifest",
  modalTitleCustomizeInvoice: "Customize Invoice",
  modalTitleManifests: "Manifests",
  modalTitleMspPartnerTenants: "MSP Tenants",
  mspChildAddSubaccountCaption: "Create integrations for this child tenant by selecting the relevant subaccounts below",
  mspChildIntegrationDocsCaption: "Prior to configuring cloud integrations for your child tenants please review this %link% to understand the nuances between integrated billing and separate billing deployments",
  mspChildIntegrationDocsLink: "documentation article",
  mspChildReviewAddSubaccountCaption: "Integrations will be created after submitting.",
  mspChildReviewUpdateSubaccountCaption: "Integrations will be updated after submitting.",
  mspChildUpdateSubaccountCaption: "Update integrations for this child tenant by selecting / deselecting the relevant subaccounts below",
  mspIncludeBigQueryExcludeConfirmationMessage: `Are you sure you want to exclude BigQuery for this  Data Integration?`,
  mspIncludeBigQueryExcludeTitle: `BigQuery data will be exclude for all subaccount in %name%`,
  mspIncludeBigQueryIncludeConfirmationMessage: `Are you sure you want to include BigQuery for this  Data Integration?`,
  mspIncludeBigQueryIncludeTitle: `BigQuery data will be included for all subaccount in %name%`,
  mspSharedIntegrationsFilterPlaceHolder: "Filter by Integration",
  mspSharedIntegrationsProviderPlaceHolder: "Filter by Provider",
  mspSharedIntegrationsRefreshStatus_1: "integration(s) initial data ingestion has not completed.",
  mspSharedIntegrationsRefreshStatus_2: "integration(s) have not been refreshed in the last 24 hours.",
  mspSharedIntegrationsRefreshStatus_3: "integration(s) have not been refreshed in the last 8 hours.",
  mspSharedIntegrationsRefreshStatus_4: "integration(s) have been refreshed in the last 8 hours.",
  mspSharedIntegrationsReviewLabel: "Review Selection",
  mspSharedIntegrationsTableHeaderAssignment: "Assignment",
  mspSharedIntegrationsTableHeaderID: "ID",
  mspSharedIntegrationsTableHeaderLastRefresh: "Last Refresh",
  mspSharedIntegrationsTableHeaderLastRefreshTooltip: "Last Refresh shows when Ternary last completed data ingestion.",
  mspSharedIntegrationsTableHeaderName: "Integration Name",
  mspSharedIntegrationsTableHeaderProvider: "Provider",
  mspSharedIntegrationsTableHeaderSubAccountID: "Subaccount ID",
  mspSharedIntegrationsTableHeaderSubAccountName: "Subaccount Name",
  mspStepCount: "Step %Step% of 2",
  mspSubaccountFormHeaderStep_1: "%action% Subaccounts",
  mspSubaccountFormHeaderStep_2: "Review Subaccount Selections",
  permissionModalTitle: "Permissions",
  reasonInputLabel: "Reason",
  removeFeatureFlagLabel: "Remove Feature Flag",
  removeFeatureFlagWarningText: "Are you sure you want to delete this feature flag? This operation cannot be undone.",
  reportsTabLabel: "Reports",
  revokeUserGlobalRolesConfirmationMessage: "Are you sure you want to revoke all global roles from %name%?",
  revokeUserGlobalRolesConfirmationTitle: "Revoke Roles",
  rolesTabLabel: "Roles",
  rolesTableHeaderCreatedAt: "Created At",
  rolesTableHeaderCreatedBy: "Created By",
  rolesTableHeaderID: "ID",
  rolesTableHeaderName: "Name",
  rolesTableHeaderUpdatedAt: "Updated At",
  rolesTableHeaderUpdatedBy: "Updated By",
  searchSubaccountInputPlaceholder: "Search Subacount...",
  settingsTabLabel: "Settings",
  statusInputLabel: "Active",
  successAddingFeatureFlagsMessage: "The feature flag has been successfully added.",
  successCreateingMspSharedPayerIntegrationsMessage: "The MSP Subaccounts have been successfully created.",
  successCreatingBillingInformationMessage: "The billing information has been successfully created.",
  successCreatingManifestMessage: "The manifest has been successfully created.",
  successCreatingMspBillingInvoiceSettingsMessage: "The invoice settings have been successfully created.",
  successCreatingTenantMessage: "The tenant has been successfully created.",
  successGrantingUsersGlobalRolesMessage: "All users have successfully been granted global roles.",
  successRemovingBillingInfoMessage: "The billing information has been successfully removed.",
  successRemovingFeatureFlagsMessage: "The feature flag has been successfully removed.",
  successRevokingUserGlobalRolesMessage: "The users global roles have been successfully revoked.",
  successUpdateMspSharedPayerIntegrationsMessage: "The MSP Subaccounts have been successfully Updated.",
  successUpdatingBillingInfoMessage: "The billing information has been successfully updated.",
  successUpdatingMspBillingInvoiceSettingsMessage: "The invoice settings have been successfully created.",
  successUpdatingTenantMessage: "The tenant has been successfully updated.",
  successUpdatingUserTenantRolesMessage: "The users roles have been successfully updated.",
  tableEmptyPlaceholderText: "No Tenants to Display",
  tableHeaderCreatedAt: "Created At",
  tableHeaderDateRange: "Date Range",
  tableHeaderExpiresOn: "Expires On",
  tableHeaderID: "ID",
  tableHeaderName: "Name",
  tableHeaderOwner: "Owner",
  tableHeaderServiceAccountEmail: "Service Account",
  tableHeaderStatus: "Status",
  tableHeaderStatusTooltip_1: " Incomplete indicates initial data ingestion has not completed",
  tableHeaderStatusTooltip_2: " Danger indicates data was not refreshed within the last 24 hours",
  tableHeaderStatusTooltip_3: " Warning indicates data was refreshed within the last 24 hours",
  tableHeaderStatusTooltip_4: " Success indicates data was refreshed within the last 8 hours",
  tableHeaderStatusTooltip: "Status indicates when Ternary last refreshed your data.",
  tableHeaderType: "Type",
  tableTypeColumnLabel_DIRECT_CUSTOMER: "Direct",
  tableTypeColumnLabel_INTERNAL: "Internal",
  tableTypeColumnLabel_MSP_CUSTOMER: "Channel Customer",
  tableTypeColumnLabel_MSP_PARENT: "MSP",
  tenantActionOption_ADD: "Add Subaccounts",
  tenantActionOption_DELETE: "Delete Subaccounts",
  tenantActionOption_UPDATE: "Update Subaccounts",
  tenantCountLabel: "Tenants",
  tenantsTabLabel: "Tenants",
  tenantStatusLabel_ACTIVE: "Active",
  tenantStatusLabel_ACTIVE_POV:" Active POV",
  tenantStatusLabel_INACTIVE: "Inactive",
  tenantStatusLabel_INACTIVE_DATA_DELETED: "Inactive Data Deleted",
  tenantTypeOption_ALL: "All",
  tenantTypeOption_DIRECT_CUSTOMER: "Direct",
  tenantTypeOption_INTERNAL: "Internal",
  tenantTypeOption_MSP_CUSTOMER: "Channel Customer",
  tenantTypeOption_MSP_PARENT: "MSP",
  typeInputLabel: "Type",
  unnassignedActivityCaption: "Unassigned",
  userCountLabel: "Users",
  usersTabLabel: "Users",
  viewPermissionsButtonLabel: "View Permissions",
  viewTenantSubaccountsButtonLabel: "View Tenant Subaccounts"
};
