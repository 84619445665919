import ErrorBoundary from "@/components/ErrorBoundary";
import useGetTenantByID from "@/hooks/useGetTenantByID";
import { useNavigateWithSearchParams } from "@/lib/react-router";
import Tabs from "@/ui-lib/components/Tabs";
import { useTheme } from "@emotion/react";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import paths from "../../../constants/paths";
import useGatekeeper from "../../../hooks/useGatekeeper";
import copyText from "../copyText";
import MspChildTenantDetailsContainer from "./MspChildTenantDetailsContainer";
import MspChildTenantIntegrationManagementContainer from "./MspChildTenantIntegrationManagementContainer";

export function MspChildTenantManagementPage(): JSX.Element {
  const gatekeeper = useGatekeeper();
  const navigate = useNavigateWithSearchParams();
  const theme = useTheme();

  //
  // Search Params
  //

  const [searchParamState, setSearchParamState] = useQueryParams({
    tab: withDefault(StringParam, "integrations"),
  });

  const { tenantID } = useParams();

  const { data: tenant } = useGetTenantByID(tenantID ?? "", {
    enabled:
      (gatekeeper.canReadTenantsSystem || gatekeeper.canReadTenantsPartner) &&
      !!tenantID,
  });

  //
  // Render
  //

  if (!gatekeeper.canAccessMspAdmin) {
    return <Navigate to={paths._home} replace />;
  }

  const containers = [
    {
      component: <MspChildTenantIntegrationManagementContainer />,
      disabled: !tenantID,
      disabledText: copyText.noTenantTooltipCaption,
      label: copyText.integrationsTabLabel,
      value: "integrations",
    },
    {
      component: <MspChildTenantDetailsContainer />,
      label: copyText.detailsTabLabel,
      value: "details",
    },
  ];

  return (
    <ErrorBoundary boundaryName="MspChildTenantManagementPage">
      <Box>
        <Button
          secondary
          iconStart={<Icon icon={faArrowLeft} />}
          type="button"
          onClick={() => navigate(paths._mspAdmin)}
        >
          {copyText.backToTenantsButtonLabel}
        </Button>
      </Box>
      <Box marginVertical={theme.space_md}>
        <Text appearance="h3">{tenant?.name ?? ""}</Text>
      </Box>
      <Tabs
        activeValue={searchParamState.tab}
        tabs={containers}
        onSelect={(tab: string) => setSearchParamState({ tab })}
      />
    </ErrorBoundary>
  );
}

export default MspChildTenantManagementPage;
