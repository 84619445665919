import externalLinks from "@/constants/externalLinks";
import useGetDataIntegrationsByTenantID from "@/features/admin/hooks/useGetDataIntegrationsByTenantID";
import { getIntegrationStatus } from "@/features/admin/utils";
import useGetMspSubaccounts, {
  MspSubaccountEntity,
} from "@/features/msp-management/hooks/useGetMspSubAccounts";
import useGatekeeper from "@/hooks/useGatekeeper";
import { DateHelper } from "@/lib/dates";
import { useNavigateWithSearchParams } from "@/lib/react-router";
import { useMspStore } from "@/lib/zustand";
import Dropdown from "@/ui-lib/components/Dropdown";
import { Option } from "@/ui-lib/components/SelectDropdown";
import TextInput from "@/ui-lib/components/TextInput";
import { roundDate } from "@/utils/dates";
import getMergeState from "@/utils/getMergeState";
import { useDebounce } from "@/utils/timers";
import { useTheme } from "@emotion/react";
import {
  faChevronDown,
  faList,
  faSearch,
} from "@fortawesome/pro-solid-svg-icons";
import { DataIntegrationEntity } from "@ternary/api-lib/core/types";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { differenceInHours } from "date-fns";
import { has, noop } from "lodash";
import React, { useMemo, useState } from "react";
import { useLocation, useParams } from "react-router";
import paths from "../../../constants/paths";
import copyText from "../copyText";
import { MspSharedIntegrations, MspSharedSubAccounts } from "../types";
import MspSharedIntegrationsTable from "./MspSharedIntegrationsTable";

export const ADD_SUBACCOUNTS = "ADD_SUBACCOUNTS";
export const UPDATE_SUBACCOUNTS = "UPDATE_SUBACCOUNTS";

interface State {
  searchText: string;
  selectedAction: string | null;
  runQueryTriggered: boolean;
}

const initialState: State = {
  searchText: "",
  selectedAction: null,
  runQueryTriggered: false,
};

export default function MspChildTenantIntegrationManagementContainer(): JSX.Element {
  const gatekeeper = useGatekeeper();
  const theme = useTheme();
  const { tenantID } = useParams();
  const dateHelper = new DateHelper();
  const mspStore = useMspStore();
  const navigate = useNavigateWithSearchParams();
  const location = useLocation();

  //
  // State
  //

  const [state, setState] = useState<State>({
    ...initialState,
    runQueryTriggered: has(location.state, "runQueryTriggered"),
  });
  const mergeState = getMergeState(setState);

  const debouncedSearchText = useDebounce(state.searchText);

  const actionOptions = [
    {
      label: copyText.tenantActionOption_ADD,
      value: ADD_SUBACCOUNTS,
      onClick: () =>
        navigate(
          paths._mspAdminManageChildTenantSubacconts.replace(
            ":tenantID",
            tenantID ?? ""
          ),
          {
            searchParams: { action: ADD_SUBACCOUNTS },
          }
        ),
    },
    {
      label: copyText.tenantActionOption_UPDATE,
      value: UPDATE_SUBACCOUNTS,
      onClick: () =>
        navigate(
          paths._mspAdminManageChildTenantSubacconts.replace(
            ":tenantID",
            tenantID ?? ""
          ),
          {
            searchParams: { action: UPDATE_SUBACCOUNTS },
          }
        ),
    },
  ];

  //
  // Queries
  //

  const {
    data: seletedIntegration = [],
    isLoading: isLoadingChildIntegrations,
  } = useGetDataIntegrationsByTenantID(tenantID ?? "", {
    enabled:
      (gatekeeper.canListDataIntegrations && !!tenantID) ||
      state.runQueryTriggered,
    meta: { errorMessage: copyText.errorLoadingDataIntegrationsMessage },
  });

  const { data: _subAccounts = [], isFetching: isLoadingSubAccounts } =
    useGetMspSubaccounts(
      {
        dateRange: [
          roundDate(dateHelper.nMonthsAgo(24)),
          roundDate(dateHelper.date),
        ],
        parentTenantID: mspStore.selectedParentTenantID as string,
      },
      { enabled: gatekeeper.canListDataIntegrations }
    );

  const mspChildSharedIntegrations = seletedIntegration.filter(
    (integration) => integration.mspChildSharedPayerConfiguration
  );

  const sharedIntegrations = getSubAccountsForMspSharedIntegrations(
    mspChildSharedIntegrations,
    _subAccounts
  );

  const filteredSharedIntegrations = useMemo(() => {
    return getFilteredSharedIntegrations({
      sharedIntegrations: sharedIntegrations ?? [],
      searchText: debouncedSearchText,
    });
  }, [debouncedSearchText, sharedIntegrations]);

  //
  // Render
  //

  const isProcessing = isLoadingChildIntegrations || isLoadingSubAccounts;

  const isEmpty =
    !sharedIntegrations.length && !isProcessing && !state.searchText.length;

  const captionFragments =
    copyText.mspChildIntegrationDocsCaption.split("%link%");

  const externalLinkCaption = (
    <Flex marginBottom={theme.space_sm} alignItems="center">
      <Text whiteSpace="pre">{captionFragments[0]}</Text>
      <a
        style={{ fontSize: theme.fontSize_ui }}
        href={externalLinks.mspBillingConfigDocs}
        rel="noreferrer"
        target="_blank"
      >
        {copyText.mspChildIntegrationDocsLink}
      </a>
      <Text whiteSpace="pre">{captionFragments[1]}</Text>
    </Flex>
  );

  const mspSharedIntegrationControls = () => {
    const selectedActionOptions = findMatchingOption(
      actionOptions,
      state.selectedAction ?? ""
    );
    return (
      <Flex direction="column">
        <Flex
          alignItems="center"
          marginBottom={theme.space_md}
          justifyContent="space-between"
          flex={1}
        >
          <Flex alignItems="center">
            <Text fontSize={theme.h4_fontSize}>
              {copyText.integrationsTabLabel}
            </Text>
          </Flex>
          <Flex>
            <Box marginRight={theme.space_md} width={300}>
              <TextInput
                iconEnd={
                  <Icon color={theme.text_color_secondary} icon={faSearch} />
                }
                onChange={(e) => mergeState({ searchText: e.target.value })}
                placeholder={copyText.searchInputPlaceholder}
                size="medium"
                value={state.searchText}
              />
            </Box>
            <Box>
              <Dropdown
                placement="bottom-end"
                options={actionOptions}
                selectedOption={
                  state.selectedAction === null
                    ? undefined
                    : selectedActionOptions
                }
              >
                <Button size="small" iconEnd={<Icon icon={faChevronDown} />}>
                  <Text>{copyText.actionButtonLable}</Text>
                </Button>
              </Dropdown>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex direction="column">
      {externalLinkCaption}
      {mspSharedIntegrationControls()}
      {isEmpty ? (
        <Flex
          direction="column"
          alignItems="center"
          justifyContent="center"
          minHeight="55vh"
          backgroundColor={theme.elevated_background_color}
        >
          <Icon size="2x" color={theme.text_color_secondary} icon={faList} />
          <Text appearance="h3" color={theme.text_color_secondary}>
            {copyText.noDataChildIntegrationsMessage}
          </Text>
          <Button
            marginTop={theme.space_sm}
            onClick={() =>
              navigate(
                paths._mspAdminManageChildTenantSubacconts.replace(
                  ":tenantID",
                  tenantID ?? ""
                ),
                {
                  searchParams: { action: ADD_SUBACCOUNTS },
                }
              )
            }
            primary
          >
            {copyText.tenantActionOption_ADD}
          </Button>
        </Flex>
      ) : (
        <MspSharedIntegrationsTable
          isProcessing={isProcessing}
          sharedIntegrations={filteredSharedIntegrations ?? []}
          showStatus
          onInteraction={noop}
        />
      )}
    </Flex>
  );
}

const dateHelper = new DateHelper();

function getSubAccountsForMspSharedIntegrations(
  integrations: DataIntegrationEntity[],
  subaccounts: MspSubaccountEntity[]
) {
  return integrations.reduce((accum: MspSharedIntegrations[], integration) => {
    const config = integration.mspChildSharedPayerConfiguration;
    const integrationDataStatus = integration.dataStatus.BILLING;
    const absoluteRefresh = integrationDataStatus.latestRefresh
      ? new Date(integrationDataStatus.latestRefresh)
      : null;

    const refreshDifference = differenceInHours(
      dateHelper.date,
      absoluteRefresh ?? 0
    );

    const statusVariant = getIntegrationStatus({
      latestRefresh: integrationDataStatus.latestRefresh,
      latestUpstream: integrationDataStatus.latestUpstreamTimestamp,
    });

    if (config) {
      const intigrationSubAccounts = subaccounts[config.parentIntegrationID];
      const newMspSubAccounts: MspSharedSubAccounts[] = [];

      if (!intigrationSubAccounts) {
        return accum;
      }
      config.subAccountFilter.map((subAccount) => {
        const getProjectName = intigrationSubAccounts.find(
          (account) => account.projectId === subAccount
        );
        if (getProjectName) {
          newMspSubAccounts.push({
            projectId: subAccount,
            projectName: getProjectName.projectName,
          });
        }
      });
      accum.push({
        id: integration.id,
        latestRefresh: integrationDataStatus.latestRefresh,
        latestUpstream: integrationDataStatus.latestUpstreamTimestamp,
        name: integration.name,
        provider: integration.providerType,
        refreshDifference,
        statusVariant,
        subAccounts: newMspSubAccounts,
      });
    }
    return accum;
  }, []);
}
type GetFilteredSharedIntegrationsParams = {
  sharedIntegrations: MspSharedIntegrations[];
  searchText: string | null;
};

function getFilteredSharedIntegrations(
  params: GetFilteredSharedIntegrationsParams
): MspSharedIntegrations[] {
  const searchText = (params.searchText ?? "").toLowerCase().trim();

  return params.sharedIntegrations.filter((sharedIntegrations) => {
    if (!isSearchTextInSharedIntegrations(sharedIntegrations, searchText)) {
      return false;
    }

    return true;
  });
}

function isSearchTextInSharedIntegrations(
  sharedIntegrations: MspSharedIntegrations,
  searchText: string
): boolean {
  if (searchText === "") return true;

  const values = [
    sharedIntegrations.name,
    sharedIntegrations.provider,
    ...sharedIntegrations.subAccounts.map((account) => account.projectName),
  ].map((value) => (value === "" || value === null ? "null" : value));

  return values.some((value) =>
    value.toLowerCase().trim().includes(searchText)
  );
}

function findMatchingOption(options: Option[], value: string) {
  const found = options.find((option) =>
    value === null ? option.value === "null" : value === option.value
  );

  return found ?? options[0];
}
