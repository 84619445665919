import {
  CreateMspSharedPayerIntegrationsParams,
  UpdateMspSharedPayerIntegrationsParams,
} from "@/api/core/types";
import ErrorBoundary from "@/components/ErrorBoundary";
import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import useGetDataIntegrationsByTenantID from "@/features/admin/hooks/useGetDataIntegrationsByTenantID";
import getMspSharedPayerIntegrationsByParentTenantID from "@/features/msp-management/hooks/useGetMspSharedPayerIntegrations";
import useGetMspSubaccounts, {
  MspSubaccountEntity,
} from "@/features/msp-management/hooks/useGetMspSubAccounts";
import useGatekeeper from "@/hooks/useGatekeeper";
import useGetTenantByID from "@/hooks/useGetTenantByID";
import { DateHelper } from "@/lib/dates";
import { useNavigateWithSearchParams } from "@/lib/react-router";
import { useMspStore } from "@/lib/zustand";
import ConfirmationModal from "@/ui-lib/components/ConfirmationModal";
import { AlertType, postAlert } from "@/utils/alerts";
import { roundDate } from "@/utils/dates";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import {
  DataIntegrationEntity,
  MspSharedPayerIntegration,
  TenantEntity,
} from "@ternary/api-lib/core/types";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { isEqual, keyBy, uniq } from "lodash";
import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import paths from "../../../constants/paths";
import copyText from "../copyText";
import useCreateMspSharedPayerIntegrations from "../hooks/useCreateMspSharedPayerIntegrations";
import useGetTenantsByParentTenantID from "../hooks/useGetTenantsByParentTenantID";
import useUpdateMspSharedPayerIntegrations from "../hooks/useUpdateMspSharedPayerIntegrations";
import { MspSharedIntegrations, MspSharedSubAccounts } from "../types";
import {
  ADD_SUBACCOUNTS,
  UPDATE_SUBACCOUNTS,
} from "./MspChildTenantIntegrationManagementContainer";
import MspManageSubaccountsSection from "./MspManageSubaccountsSection";
import MspReviewSubaccountsSection from "./MspReviewSubaccountsSection";
import MspSharedIntegrationsTable from "./MspSharedIntegrationsTable";

const MODAL_INCLUDE_BIG_QUERY = "INCLUDE_BIG_QUERY";

type Interaction =
  | MspSharedIntegrationsTable.Interaction
  | MspReviewSubaccountsSection.Interaction;

export type SelectedSubAccounts = {
  [intigrationId: string]: {
    subaccounts: string[];
    includeBigQuery: boolean;
  };
};

type SubAccountForMspSharedPrams = {
  action: string | null | undefined;
  childIntegrations: MspSharedPayerIntegration[];
  currentTenant: TenantEntity;
  integrations: DataIntegrationEntity[];
  selectedSubAccounts: SelectedSubAccounts;
  subaccounts: MspSubaccountEntity[];
};

interface State {
  initialSubaccounts: SelectedSubAccounts;
  includeBigQuery: boolean;
  modalKey: string;
  selectedParentInigrationId: string | null;
  selectedSubAccounts: SelectedSubAccounts;
}

const initialState: State = {
  initialSubaccounts: {},
  includeBigQuery: false,
  modalKey: "",
  selectedParentInigrationId: null,
  selectedSubAccounts: {},
};

export function MspSubaccountsFormContainer(): JSX.Element {
  const activityTracker = useActivityTracker();
  const gatekeeper = useGatekeeper();
  const navigate = useNavigateWithSearchParams();
  const { tenantID } = useParams();
  const theme = useTheme();
  const dateHelper = new DateHelper();
  const mspStore = useMspStore();

  //
  // State
  //

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  const [searchParamState, setSearchParamState] = useQueryParams({
    step: withDefault(StringParam, "1"),
    action: withDefault(StringParam, ADD_SUBACCOUNTS),
  });

  //
  // Queries
  //

  const { data: tenant, isLoading: isLoadingTenant } = useGetTenantByID(
    tenantID ?? "",
    {
      enabled:
        (gatekeeper.canReadTenantsSystem || gatekeeper.canReadTenantsPartner) &&
        !!tenantID,
    }
  );

  if (!gatekeeper.canAccessMspAdmin) {
    return <Navigate to={paths._home} replace />;
  }

  const {
    data: _parentIntegrations = [],
    isLoading: isLoadingParentIntegrations,
  } = useGetDataIntegrationsByTenantID(tenant?.parentTenantID ?? "", {
    enabled: gatekeeper.canListDataIntegrations && !!tenant,
    meta: { errorMessage: copyText.errorLoadingDataIntegrationsMessage },
  });

  const { data: _mspTenants = [], isLoading: isLoadingMspTenants } =
    useGetTenantsByParentTenantID(tenant?.parentTenantID ?? "", {
      enabled: gatekeeper.canReadTenantsPartner && !!tenant,
    });

  const { data: _subaccounts = [], isFetching: isLoadingSubAccounts } =
    useGetMspSubaccounts(
      {
        dateRange: [
          roundDate(dateHelper.nMonthsAgo(24)),
          roundDate(dateHelper.date),
        ],
        parentTenantID: mspStore.selectedParentTenantID as string,
      },
      { enabled: gatekeeper.canListDataIntegrations && !!tenant }
    );

  const {
    data: _childIntegrations = [],
    isLoading: isLoadingChildIntegrations,
  } = getMspSharedPayerIntegrationsByParentTenantID(
    tenant?.parentTenantID ?? "",
    {
      enabled: !!tenant,
    }
  );

  const tenantsKeyedByID = keyBy(_mspTenants, "id");

  useEffect(() => {
    if (searchParamState.action !== UPDATE_SUBACCOUNTS) {
      return;
    }

    const initialSubaccounts = _childIntegrations.reduce(
      (accum: SelectedSubAccounts, integration) => {
        const config = integration.sharedPayerConfig;
        if (!config) {
          return accum;
        }
        if (
          integration.tenantDocID === tenantsKeyedByID[tenantID ?? ""].fsDocID
        ) {
          accum[integration.id] = {
            subaccounts: config.subAccountFilter,
            includeBigQuery: config.copyBQ,
          };
        }
        return accum;
      },
      {}
    );

    mergeState({
      selectedSubAccounts: initialSubaccounts,
      initialSubaccounts: initialSubaccounts,
    });
  }, [_childIntegrations]);

  //
  // Mutations
  //

  const {
    isPending: isCreateingMspSharedPayerIntegrations,
    mutate: createMspSharedPayerIntegrations,
  } = useCreateMspSharedPayerIntegrations({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorCreateingMspSharedPayerIntegrationsMessage,
      });
    },
    onSuccess: () => {
      navigate(
        paths._mspAdminManageChildTenant.replace(":tenantID", tenantID ?? ""),
        {
          state: {
            runQueryTriggered: true,
          },
        }
      );
      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successCreateingMspSharedPayerIntegrationsMessage,
      });
    },
  });

  const {
    isPending: isUpdatingMspSharedPayerIntegrations,
    mutate: updateMspSharedPayerIntegrations,
  } = useUpdateMspSharedPayerIntegrations({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorUpdateingMspSharedPayerIntegrationsMessage,
      });
    },
    onSuccess: () => {
      navigate(
        paths._mspAdminManageChildTenant.replace(":tenantID", tenantID ?? ""),
        {
          state: {
            runQueryTriggered: true,
          },
        }
      );
      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successUpdateMspSharedPayerIntegrationsMessage,
      });
    },
  });

  //
  // Interaction Handlers
  //

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case MspSharedIntegrationsTable.INTERACTION_LINK_CLICKED: {
        const getSelectedIntigration = _childIntegrations.find(
          (integration) => {
            const config = integration.sharedPayerConfig;
            if (
              config &&
              config.subAccountFilter.includes(interaction.subAccountID)
            ) {
              if (
                searchParamState.action === ADD_SUBACCOUNTS &&
                config.parentIntegrationID === interaction.integrationID
              ) {
                return true;
              }

              return true;
            }
          }
        );

        if (getSelectedIntigration) {
          const tenantsKeyedByDocID = keyBy(_mspTenants, "fsDocID");
          const selectedTenant =
            tenantsKeyedByDocID[getSelectedIntigration.tenantDocID];

          activityTracker.captureAction(
            actions.SELECT_MSP_SUBACCOUNT_TO_INVESTIGATE_IN_MANAGE_TENANT,
            { value: selectedTenant.id }
          );
          window.open(
            `${window.location.origin}${paths._mspAdminManageChildTenant.replace(":tenantID", selectedTenant.id)}`,
            "_blank"
          );
        }
        break;
      }
      case MspSharedIntegrationsTable.INTERACTION_SWIITCH_ON_CHANGE: {
        activityTracker.captureAction(
          actions.SELECT_MSP_SUBACCOUNTS_REVIEW_INCLUDE_BIGQUERY
        );
        mergeState({
          includeBigQuery: interaction.includeBigQuery,
          modalKey: MODAL_INCLUDE_BIG_QUERY,
          selectedParentInigrationId: interaction.integrationID,
        });
        break;
      }
      case MspSharedIntegrationsTable.INTERACTION_CHECKBOX_CLICKED: {
        setState((prevState) => {
          const prevSelectedSubAccounts =
            prevState.selectedSubAccounts[interaction.integrationID]
              ?.subaccounts ?? [];
          const updatedSeletedCase = interaction.isSelected
            ? [...prevSelectedSubAccounts, interaction.subAccountID]
            : prevSelectedSubAccounts.filter(
                (id) => id !== interaction.subAccountID
              );

          return {
            ...prevState,
            selectedSubAccounts: {
              ...prevState.selectedSubAccounts,
              [interaction.integrationID]: {
                ...prevState.selectedSubAccounts[interaction.integrationID],
                subaccounts: updatedSeletedCase,
              },
            },
          };
        });
        break;
      }
      case MspReviewSubaccountsSection.INTERACTION_CREATE_SUBMIT_CLICKED: {
        if (!tenant?.parentTenantID) {
          return;
        }

        const sharedPayerConfig = Object.keys(state.selectedSubAccounts).map(
          (key) => {
            return {
              copyBQ: state.selectedSubAccounts[key].includeBigQuery ?? false,
              parentIntegrationID: key,
              subAccountFilter: state.selectedSubAccounts[key].subaccounts,
            };
          }
        );

        const createSharedPayerIntegrations: CreateMspSharedPayerIntegrationsParams =
          {
            parentTenantID: tenant.parentTenantID,
            childTenantID: tenant.id,
            childSharedPayerConfigurations: sharedPayerConfig,
          };

        activityTracker.captureAction(
          actions.CLICK_MSP_SHARED_PAYER_INTEGRATIONS_CREATE
        );

        createMspSharedPayerIntegrations(createSharedPayerIntegrations);
        break;
      }
      case MspReviewSubaccountsSection.INTERACTION_UPDATE_SUBMIT_CLICKED: {
        const sharedPayerConfig = Object.keys(state.selectedSubAccounts)
          .filter(
            (key) =>
              !isEqual(
                state.initialSubaccounts[key].subaccounts,
                state.selectedSubAccounts[key].subaccounts
              )
          )
          .map((key) => {
            return {
              copyBQ: state.selectedSubAccounts[key].includeBigQuery ?? false,
              integrationID: key,
              subAccountFilters: state.selectedSubAccounts[key].subaccounts,
            };
          });

        const updateSharedPayerIntegrations: UpdateMspSharedPayerIntegrationsParams =
          {
            paramsArray: sharedPayerConfig,
          };

        activityTracker.captureAction(
          actions.CLICK_MSP_SHARED_PAYER_INTEGRATIONS_UPDATE
        );

        updateMspSharedPayerIntegrations(updateSharedPayerIntegrations);
      }
    }
  }

  const sharedIntegrations = getSubAccountsForMspSharedIntegrations({
    action: searchParamState.action,
    childIntegrations: _childIntegrations,
    currentTenant: tenantsKeyedByID[tenantID ?? ""],
    integrations: _parentIntegrations,
    selectedSubAccounts: state.selectedSubAccounts,
    subaccounts: _subaccounts,
  });

  function canSubmit() {
    if (searchParamState.action === ADD_SUBACCOUNTS) {
      return Object.keys(state.selectedSubAccounts).some((key) => {
        return state.selectedSubAccounts[key].subaccounts.length > 0;
      });
    } else {
      const validSubAccounts = Object.keys(state.selectedSubAccounts).some(
        (key) => {
          const initialSubAccounts = state.initialSubaccounts[key].subaccounts;
          return (
            state.selectedSubAccounts[key].subaccounts.length > 0 &&
            !isEqual(
              initialSubAccounts,
              state.selectedSubAccounts[key].subaccounts
            )
          );
        }
      );
      return validSubAccounts;
    }
  }

  const isProcessing =
    isLoadingChildIntegrations ||
    isLoadingSubAccounts ||
    isLoadingParentIntegrations ||
    isLoadingMspTenants ||
    isCreateingMspSharedPayerIntegrations ||
    isUpdatingMspSharedPayerIntegrations ||
    isLoadingTenant;

  function renderModal(): JSX.Element | null {
    switch (state.modalKey) {
      case MODAL_INCLUDE_BIG_QUERY: {
        const selectedParentID = state.selectedParentInigrationId;
        const parentIntigration = _parentIntegrations.find(
          (integration) => integration.id === selectedParentID
        );
        if (!selectedParentID || !parentIntigration) return null;

        const conformTitle = state.includeBigQuery
          ? copyText.mspIncludeBigQueryIncludeTitle
          : copyText.mspIncludeBigQueryExcludeTitle;
        const conformMessage = state.includeBigQuery
          ? copyText.mspIncludeBigQueryIncludeConfirmationMessage
          : copyText.mspIncludeBigQueryExcludeConfirmationMessage;

        return (
          <ConfirmationModal
            title={conformTitle.replace("%name%", parentIntigration.name)}
            message={conformMessage}
            onConfirm={() =>
              setState((prevState) => {
                return {
                  ...prevState,
                  selectedSubAccounts: {
                    ...prevState.selectedSubAccounts,
                    [selectedParentID]: {
                      ...prevState.selectedSubAccounts[selectedParentID],
                      includeBigQuery: state.includeBigQuery,
                    },
                  },
                  modalKey: "",
                  selectedParentInigrationId: null,
                };
              })
            }
            onCancel={() =>
              mergeState({
                selectedParentInigrationId: null,
                modalKey: "",
                includeBigQuery: !state.includeBigQuery,
              })
            }
          />
        );
      }
    }
    return null;
  }

  function renderHeader() {
    switch (searchParamState.action) {
      case UPDATE_SUBACCOUNTS:
        return copyText.mspSubaccountFormHeaderStep_1.replace(
          "%action%",
          "Update"
        );

      default:
        return copyText.mspSubaccountFormHeaderStep_1.replace(
          "%action%",
          "Add"
        );
    }
  }

  return (
    <ErrorBoundary boundaryName="MspSubaccountsPage">
      {renderModal()}
      <Box>
        <Button
          secondary
          iconStart={<Icon icon={faArrowLeft} />}
          type="button"
          onClick={() => {
            if (searchParamState.step === "2") {
              setSearchParamState({ step: "1" });
            } else {
              navigate(
                paths._mspAdminManageChildTenant.replace(
                  ":tenantID",
                  tenantID ?? ""
                )
              );
            }
          }}
        >
          {copyText.actionBack}
        </Button>
      </Box>
      <Box marginVertical={theme.space_md}>
        <Text appearance="h3">{tenant?.name ?? ""}</Text>
      </Box>
      <Box marginTop={theme.space_md}>
        <Text>
          {copyText.mspStepCount.replace("%Step%", searchParamState.step)}
        </Text>
      </Box>
      <Box marginVertical={theme.space_md}>
        <Text appearance="h4">
          {searchParamState.step === "1"
            ? renderHeader()
            : copyText.mspSubaccountFormHeaderStep_2}
        </Text>
      </Box>

      {searchParamState.step === "1" ? (
        <MspManageSubaccountsSection
          action={searchParamState.action}
          canSubmit={canSubmit()}
          isProcessing={isProcessing}
          selectedSubAccounts={state.selectedSubAccounts}
          sharedIntegrations={sharedIntegrations}
          onNextStep={(step: string) => setSearchParamState({ step })}
          onInteraction={handleInteraction}
        />
      ) : (
        <MspReviewSubaccountsSection
          action={searchParamState.action}
          initialSubaccounts={state.initialSubaccounts}
          isProcessing={isProcessing}
          selectedSubAccounts={state.selectedSubAccounts}
          sharedIntegrations={sharedIntegrations}
          onNextStep={(step: string) => setSearchParamState({ step })}
          onInteraction={handleInteraction}
        />
      )}
    </ErrorBoundary>
  );
}

function getSubAccountsForMspSharedIntegrations({
  action,
  childIntegrations,
  currentTenant,
  integrations,
  selectedSubAccounts,
  subaccounts,
}: SubAccountForMspSharedPrams) {
  const currentParentIntegrationIds: string[] = [];
  const tenantIntegrations = childIntegrations.reduce(
    (accum: MspSharedPayerIntegration[], integration) => {
      const config = integration.sharedPayerConfig;
      if (!config) {
        return accum;
      }
      if (integration.tenantDocID === currentTenant.fsDocID) {
        currentParentIntegrationIds.push(config.parentIntegrationID);
        accum = [...accum, integration];
      }
      return accum;
    },
    []
  );

  const assignedSubaccounts = getAssignedSubAccountsFromMspSharedIntegrations(
    childIntegrations,
    currentTenant
  );

  return integrations.reduce((accum: MspSharedIntegrations[], integration) => {
    const assignedChildSubaccounts = assignedSubaccounts[integration.id] ?? [];

    const subAccounts: MspSharedSubAccounts[] =
      subaccounts[integration.id] ?? [];

    const newMspSubAccounts: MspSharedSubAccounts[] = [];

    if (!subAccounts.length) {
      return accum;
    }

    if (
      action === ADD_SUBACCOUNTS &&
      !currentParentIntegrationIds.includes(integration.id)
    ) {
      const selectedChildSubaccounts =
        selectedSubAccounts[integration.id]?.subaccounts ?? [];

      subAccounts.map((subaccount) => {
        newMspSubAccounts.push({
          fsDocID: integration.tenantDocID,
          isDisabled:
            assignedChildSubaccounts.includes(subaccount.projectId) &&
            !selectedChildSubaccounts.includes(subaccount.projectId),
          isSelected: selectedChildSubaccounts.includes(subaccount.projectId),
          projectId: subaccount.projectId,
          projectName: subaccount.projectName ?? subaccount.projectId,
        });
      });

      accum.push({
        id: integration.id,
        includeBigQuery:
          selectedSubAccounts[integration.id]?.includeBigQuery ?? false,
        name: integration.name,
        provider: integration.providerType,
        subAccounts: newMspSubAccounts,
      });
      return accum;
    }

    if (
      action === UPDATE_SUBACCOUNTS &&
      currentParentIntegrationIds.includes(integration.id)
    ) {
      const sharedIntegration = tenantIntegrations.find(
        (tenantIntegration) =>
          tenantIntegration.sharedPayerConfig?.parentIntegrationID ===
          integration.id
      );

      if (!sharedIntegration) {
        return accum;
      }

      const selectedChildSubaccounts =
        selectedSubAccounts[sharedIntegration.id]?.subaccounts ?? [];

      subAccounts.map((subaccount) => {
        newMspSubAccounts.push({
          fsDocID: integration.tenantDocID,
          isDisabled:
            assignedChildSubaccounts.includes(subaccount.projectId) &&
            !selectedChildSubaccounts.includes(subaccount.projectId),
          isSelected: selectedChildSubaccounts.includes(subaccount.projectId),
          projectId: subaccount.projectId,
          projectName: subaccount.projectName ?? subaccount.projectId,
        });
      });

      accum.push({
        id: sharedIntegration.id,
        includeBigQuery:
          selectedSubAccounts[integration.id]?.includeBigQuery ?? false,
        name: sharedIntegration.name,
        provider: sharedIntegration.providerType,
        subAccounts: newMspSubAccounts,
      });
      return accum;
    }
    return accum;
  }, []);
}

function getAssignedSubAccountsFromMspSharedIntegrations(
  childIntegrations: MspSharedPayerIntegration[],
  currentTenant: TenantEntity
) {
  return childIntegrations.reduce(
    (accum: { [intigrationID: string]: string[] }, integration) => {
      const config = integration.sharedPayerConfig;
      if (!config) return accum;
      if (integration.tenantDocID === currentTenant.fsDocID) return accum;

      if (accum[config.parentIntegrationID]) {
        uniq(
          (accum[config.parentIntegrationID] = [
            ...accum[config.parentIntegrationID],
            ...config.subAccountFilter,
          ])
        );
      } else {
        accum[config.parentIntegrationID] = config.subAccountFilter;
      }
      return accum;
    },
    {}
  );
}
