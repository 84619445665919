import React, { useEffect, useRef } from "react";
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import Layout from "./components/Layout";
import paths from "./constants/paths";
import { TENANT_ID_KEY } from "./constants/searchParams";
import HomeContainer from "./containers/HomeContainer";
import { useActivityTracker } from "./context/ActivityTrackerProvider";
import AdminPage from "./features/admin/components/AdminPage";
import UserManagementContainer from "./features/admin/components/UserManagementContainer";
import AlertFeedContainer from "./features/alert-tracking/components/AlertFeedContainer";
import AlertTrackingPage from "./features/alert-tracking/components/AlertTrackingPage";
import BudgetsManagementContainer from "./features/budget-management/components/BudgetsManagementContainer";
import CaseManagementContainer from "./features/case-management/components/CaseManagementContainer";
import CaseViewContainer from "./features/case-management/components/CaseViewContainer";
import { AWSCommittedUsePage } from "./features/committed-use/aws/components/AWSCommittedUsePage";
import { AzureCommittedUsePage } from "./features/committed-use/azure/components/AzureCommittedUsePage";
import { CommittedUsePage } from "./features/committed-use/gcp/components/CommittedUsePage";
import CostCompareContainer from "./features/cost-assist/components/CostCompareContainer";
import CostCompareTakeoutContainer from "./features/cost-assist/components/CostCompareTakeoutContainer";
import CostReportContainer from "./features/cost-report/components/CostReportContainer";
import ForecastingViewContainer from "./features/forecasting/components/ForecastingViewContainer";
import InternalAdminPage from "./features/global-admin/components/InternalAdminPage";
import MspAdminPage from "./features/global-admin/components/MspAdminPage";
import MspChildTenantManagementPage from "./features/global-admin/components/MspChildTenantManagementPage";
import { MspSubaccountsFormContainer } from "./features/global-admin/components/MspSubaccountsFormContainer";
import TenantManagementContainer from "./features/global-admin/components/TenantManagementContainer";
import AWSComputeInsightsPage from "./features/insights/aws-compute/components/AWSComputeInsightsPage";
import AWSDataWarehouseInsightsPage from "./features/insights/aws-data-warehouse/components/AWSDataWarehouseInsightsPage";
import AWSDatabaseElastiCacheInsightsPage from "./features/insights/aws-database-elasticache/components/AWSDatabaseElastiCacheInsightsPage";
import AWSDatabaseMemoryDBInsightsPage from "./features/insights/aws-database-memory-db/components/AWSDatabaseMemoryDBInsightsPage";
import AWSDatabaseInsightsPage from "./features/insights/aws-database/components/AWSDatabaseInsightsPage";
import AWSKubernetesInsightsPage from "./features/insights/aws-kubernetes/components/AWSKubernetesInsightsPage";
import AWSOpenSearchInsightsPage from "./features/insights/aws-open-search/components/AWSOpenSearchDatabaseInsightsPage";
import AwsEbsInsightsPage from "./features/insights/aws-storage-ebs/components/AWSStorageEBSInsightsPage";
import AWSStorageS3InsightsPage from "./features/insights/aws-storage-s3/components/AWSStorageS3InsightsPage";
import AzureDatabaseInsightsPage from "./features/insights/azure-database-sql/components/AzureDatabaseInsightsPage";
import AzureKubernetesInsightsPage from "./features/insights/azure-kubernetes/components/AzureKubernetesInsightsPage";
import AzureStorageBBInsightsPage from "./features/insights/azure-storage-bb/components/AzureStorageBBInsightsPage";
import AzureVMComputeInsightsPage from "./features/insights/azure-vm-compute/components/AzureVMComputeInsightsPage";
import ComputeInsightsLandingPage from "./features/insights/components/ComputeInsightsLandingPage";
import DataWarehoueInsightsLandingPage from "./features/insights/components/DataWarehouseInsightsLandingPage";
import DatabaseInsightsLandingPage from "./features/insights/components/DatabaseInsightsLandingPage";
import KubernetesInsightsLandingPage from "./features/insights/components/KubernetesInsightsLandingPage";
import StorageInsightsLandingPage from "./features/insights/components/StorageInsightsLandingPage";
import GCPCloudRunInsightsPage from "./features/insights/gcp-compute-cloud-run/components/GCPCloudRunPage";
import GCPComputeInsightsPage from "./features/insights/gcp-compute-gce/components/GCPComputeInsightsPage";
import GcpBigQueryInsightsPage from "./features/insights/gcp-data-warehouse/components/GcpBigQueryInsightsPage";
import GCPDatabaseSpannerInsightsPage from "./features/insights/gcp-database-cloud-spanner/components/GCPDatabaseSpannerInsightsPage";
import GCPDatabaseCloudSqlInsightsPage from "./features/insights/gcp-database-cloud-sql/components/GCPDatabaseCloudSqlInsightsPage";
import GCPKubernetesInsightsPage from "./features/insights/gcp-kubernetes/components/GCPKubernetesInsightsPage";
import GCPStorageInsightsPage from "./features/insights/gcp-storage/components/GCPStorageInsightsPage";
import SnowflakeDataWarehouseInsightsPage from "./features/insights/snowflake-data-warehouse/components/SnowflakeDataWarehouseInsightsPage";
import SnowflakeDatabaseInsightsPage from "./features/insights/snowflake-database/components/SnowflakeDatabaseInsightsPage";
import CustomLabelsManagerContainer from "./features/label-management/components/CustomLabelManagementContainer";
import LabelGroupingRuleManagementContainer from "./features/label-management/components/LabelGroupingRuleManagementContainer";
import MspBillingRuleSetContainer from "./features/msp-management/components/MspBillingRuleSetContainer";
import MspDashboardPage from "./features/msp-management/components/MspDashboardPage";
import RampPlansContainer from "./features/ramp-plans/components/RampPlansContainer";
import ReallocationPage from "./features/reallocations/components/ReallocationPage";
import DashboardManagementContainer from "./features/reporting-engine/components/DashboardManagementContainer";
import DashboardViewContainer from "./features/reporting-engine/components/DashboardViewContainer";
import ReportBuilderContainer from "./features/reporting-engine/components/ReportBuilderContainer";
import ReportManagementContainer from "./features/reporting-engine/components/ReportManagementContainer";
import UserSettingsManagementPage from "./features/user-settings/components/UserSettingsManagementPage";
import useAuthenticatedUser from "./hooks/useAuthenticatedUser";
import NotFound from "./pages/NotFound";

export default function AppRouter(): JSX.Element {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  //
  // Side Effects
  //

  const lastPath = useRef<string | null>(null);

  // User navigation tracking
  useEffect(() => {
    const leaveCallback = () => activityTracker.onLeavePage();
    const currentPath = new URL(window.location.href).pathname;

    // First page
    if (lastPath.current === null) {
      activityTracker.setPage(currentPath);
      window.addEventListener("beforeunload", leaveCallback);
    }
    // Not first Page
    else {
      leaveCallback();
      activityTracker.setPage(currentPath);
    }

    lastPath.current = currentPath;

    return () => {
      window.removeEventListener("beforeunload", leaveCallback);
    };
  }, [location]);

  // Enforce tenant_id being uuid
  useEffect(() => {
    const tenantID = searchParams.get(TENANT_ID_KEY);

    if (tenantID === authenticatedUser.tenant.id) return;

    searchParams.set(TENANT_ID_KEY, authenticatedUser.tenant.id);

    setSearchParams(searchParams, { replace: true });
  }, [location]);

  //
  // Render
  //

  // Using a slightly different layout for the new report builder page.
  const reportBuilderLayout = location.pathname.includes(
    paths._reportBuilderNew
  );

  // All Top Level Page Routes - Alphabetized by path name
  return (
    <Routes>
      <Route
        path="/"
        element={<Layout reportBuilderLayout={reportBuilderLayout} />}
      >
        <Route path={paths._admin} element={<AdminPage />} />
        <Route path={paths._alertRuleFeed} element={<AlertFeedContainer />} />
        <Route path={paths._alertTracking} element={<AlertTrackingPage />} />
        <Route path={paths._budgets} element={<BudgetsManagementContainer />} />
        <Route path={paths._case} element={<CaseViewContainer />} />
        <Route path={paths._cases} element={<CaseManagementContainer />} />
        <Route
          path={paths._commitmentDiscountAWS}
          element={<AWSCommittedUsePage />}
        />
        <Route
          path={paths._commitmentDiscountAzure}
          element={<AzureCommittedUsePage />}
        />
        <Route
          path={paths._commitmentDiscountGCP}
          element={<CommittedUsePage />}
        />
        <Route
          path={paths._costAssistCostCompare}
          element={<CostCompareContainer />}
        />
        <Route
          path={paths._costAssistCostCompareTakeout}
          element={<CostCompareTakeoutContainer />}
        />
        <Route
          path={paths._costAssistRampPlans}
          element={<RampPlansContainer />}
        />
        <Route
          path={paths._costAssistReallocations}
          element={<ReallocationPage />}
        />
        <Route path={paths._costReports} element={<CostReportContainer />} />
        <Route
          path={paths._customLabels}
          element={<CustomLabelsManagerContainer />}
        />
        <Route path={paths._dashboard} element={<DashboardViewContainer />} />
        <Route
          path={paths._dashboards}
          element={<DashboardManagementContainer />}
        />
        <Route
          path={paths._forecasting}
          element={<ForecastingViewContainer />}
        />
        <Route path={paths._home} element={<HomeContainer />} />
        <Route
          path={paths._insightsBigQuery}
          element={<GcpBigQueryInsightsPage />}
        />
        <Route
          path={paths._insightsCompute}
          element={<ComputeInsightsLandingPage />}
        />
        <Route
          path={paths._insightsComputeAzureVM}
          element={<AzureVMComputeInsightsPage />}
        />
        <Route
          path={paths._insightsComputeCloudRun}
          element={<GCPCloudRunInsightsPage />}
        />
        <Route
          path={paths._insightsComputeEC2}
          element={<AWSComputeInsightsPage />}
        />
        <Route
          path={paths._insightsComputeGCE}
          element={<GCPComputeInsightsPage />}
        />
        <Route
          path={paths._insightsDatabase}
          element={<DatabaseInsightsLandingPage />}
        />
        <Route
          path={paths._insightsDatabaseAzureSQL}
          element={<AzureDatabaseInsightsPage />}
        />
        <Route
          path={paths._insightsDatabaseCloudSQL}
          element={<GCPDatabaseCloudSqlInsightsPage />}
        />
        <Route
          path={paths._insightsDatabaseElastiCache}
          element={<AWSDatabaseElastiCacheInsightsPage />}
        />
        <Route
          path={paths._insightsDatabaseMemoryDB}
          element={<AWSDatabaseMemoryDBInsightsPage />}
        />
        <Route
          path={paths._insightsDatabaseOpenSearch}
          element={<AWSOpenSearchInsightsPage />}
        />
        <Route
          path={paths._insightsDatabaseRDS}
          element={<AWSDatabaseInsightsPage />}
        />
        <Route
          path={paths._insightsDatabaseSnowflake}
          element={<SnowflakeDatabaseInsightsPage />}
        />
        <Route
          path={paths._insightsDatabaseSpanner}
          element={<GCPDatabaseSpannerInsightsPage />}
        />
        <Route
          path={paths._insightsDataWarehouse}
          element={<DataWarehoueInsightsLandingPage />}
        />
        <Route
          path={paths._insightsDataWarehousRedshift}
          element={<AWSDataWarehouseInsightsPage />}
        />
        <Route
          path={paths._insightsDataWarehouseSnowflake}
          element={<SnowflakeDataWarehouseInsightsPage />}
        />
        <Route
          path={paths._insightsKubernetes}
          element={<KubernetesInsightsLandingPage />}
        />
        <Route
          path={paths._insightsKubernetesAKS}
          element={<AzureKubernetesInsightsPage />}
        />
        <Route
          path={paths._insightsKubernetesEKS}
          element={<AWSKubernetesInsightsPage />}
        />
        <Route
          path={paths._insightsKubernetesGKE}
          element={<GCPKubernetesInsightsPage />}
        />
        <Route
          path={paths._insightsStorageGCS}
          element={<GCPStorageInsightsPage />}
        />
        <Route
          path={paths._insightsStorage}
          element={<StorageInsightsLandingPage />}
        />
        <Route
          path={paths._insightsStorageBB}
          element={<AzureStorageBBInsightsPage />}
        />
        <Route
          path={paths._insightsStorageEBS}
          element={<AwsEbsInsightsPage />}
        />
        <Route
          path={paths._insightsStorageGCS}
          element={<GCPStorageInsightsPage />}
        />
        <Route
          path={paths._insightsStorageS3}
          element={<AWSStorageS3InsightsPage />}
        />
        <Route path={paths._internalAdmin} element={<InternalAdminPage />} />
        <Route
          path={paths._internalMspPartnerTenants}
          element={<TenantManagementContainer />}
        />
        <Route
          path={paths._internalTenantUsers}
          element={<UserManagementContainer />}
        />
        <Route
          path={paths._labelGroupingRules}
          element={<LabelGroupingRuleManagementContainer />}
        />
        <Route path={paths._mspAdmin} element={<MspAdminPage />} />
        <Route
          path={paths._mspAdminTenantUsers}
          element={<UserManagementContainer />}
        />
        <Route
          path={paths._mspAdminManageTenant}
          element={<MspChildTenantManagementPage />}
        />
        <Route
          path={paths._mspAdminManageChildTenant}
          element={<MspChildTenantManagementPage />}
        />
        <Route
          path={paths._mspAdminManageChildTenantSubacconts}
          element={<MspSubaccountsFormContainer />}
        />
        <Route
          path={paths._mspBillingRulesEngine}
          element={<MspBillingRuleSetContainer />}
        />
        <Route path={paths._mspDashboard} element={<MspDashboardPage />} />
        <Route path={paths._mspMgmt} element={<MspAdminPage />} />
        <Route
          path={paths._reportBuilder}
          element={<ReportBuilderContainer />}
        />
        <Route
          path={paths._reportBuilderNew}
          element={<ReportBuilderContainer />}
        />
        <Route path={paths._reports} element={<ReportManagementContainer />} />
        <Route
          path={paths._settings}
          element={<UserSettingsManagementPage />}
        />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
