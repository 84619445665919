export const mspRollupMeasures = {
  billedCost: "billedCost",
  listCost: "listCost",
  userCost: "userCost",
} as const;

export const mspRollupDimensions = {
  billingAccountID: "billingAccountId",
  invoiceMonth: "invoiceMonth",
  provider: "provider",
  region: "region",
  serviceName: "serviceName",
  tenantID: "tenantId",
  subAccountId: "subAccountId",
  projectName: "projectName",
  projectId: "projectId",
} as const;
