import { defaultDimensionsMap } from "@ternary/api-lib/constants/analytics";
import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import { AddFeatureFlagCommandResult } from "@ternary/api-lib/core/commands/AddFeatureFlagCommand";
import { CreateAWSIntegrationCommandResult } from "@ternary/api-lib/core/commands/CreateAWSIntegrationCommand";
import { CreateAlertRuleCommandResult } from "@ternary/api-lib/core/commands/CreateAlertRuleCommand";
import { CreateAlibabaIntegrationCommandResult } from "@ternary/api-lib/core/commands/CreateAlibabaIntegrationCommand";
import { CreateApiKeyCommandResult } from "@ternary/api-lib/core/commands/CreateApiKeyCommand";
import { CreateAzureIntegrationCommandResult } from "@ternary/api-lib/core/commands/CreateAzureIntegrationCommand";
import { CreateBudgetCommandResult } from "@ternary/api-lib/core/commands/CreateBudgetCommand";
import { CreateCaseCommandResult } from "@ternary/api-lib/core/commands/CreateCaseCommand";
import { CreateCaseCommentCommandResult } from "@ternary/api-lib/core/commands/CreateCaseCommentCommand";
import { CreateCostCompareBillCommandResult } from "@ternary/api-lib/core/commands/CreateCostCompareBillCommand";
import { CreateCustomLabelsCommandResult } from "@ternary/api-lib/core/commands/CreateCustomLabelsCommand";
import { CreateCustomMetricCommandResult } from "@ternary/api-lib/core/commands/CreateCustomMetricCommand";
import { CreateDashboardCommandResult } from "@ternary/api-lib/core/commands/CreateDashboardCommand";
import { CreateDimensionPreferencesCommandResult } from "@ternary/api-lib/core/commands/CreateDimensionPreferencesCommand";
import { CreateGCPIntegrationCommandResult } from "@ternary/api-lib/core/commands/CreateGCPIntegrationCommand";
import { CreateLabelGroupingRuleCommandResult } from "@ternary/api-lib/core/commands/CreateLabelGroupingRuleCommand";
import { CreateMeasurePreferencesCommandResult } from "@ternary/api-lib/core/commands/CreateMeasurePreferencesCommand";
import { CreateMspBillingInfoCommandResult } from "@ternary/api-lib/core/commands/CreateMspBillingInfoCommand";
import { CreateMspBillingInvoiceCommandResult } from "@ternary/api-lib/core/commands/CreateMspBillingInvoiceCommand";
import { CreateMspBillingManifestCommandResult } from "@ternary/api-lib/core/commands/CreateMspBillingManifestCommand";
import { CreateMspBillingRuleSetCommandResult } from "@ternary/api-lib/core/commands/CreateMspBillingRuleSetCommand";
import { CreateMspSharedPayerIntegrationsCommandResult } from "@ternary/api-lib/core/commands/CreateMspSharedPayerIntegrationsCommand";
import { CreateOracleIntegrationCommandResult } from "@ternary/api-lib/core/commands/CreateOracleIntegrationCommand";
import { CreateRampPlanCommandResult } from "@ternary/api-lib/core/commands/CreateRampPlanCommand";
import { CreateReallocationCommandResult } from "@ternary/api-lib/core/commands/CreateReallocationCommand";
import { CreateReportCommandResult } from "@ternary/api-lib/core/commands/CreateReportCommand";
import { CreateResourceSubscriptionCommandResult } from "@ternary/api-lib/core/commands/CreateResourceSubscriptionCommand";
import { CreateSavingsOpportunityFilterCommandResult } from "@ternary/api-lib/core/commands/CreateSavingsOpportunityFilterCommand";
import { CreateScopedViewCommandResult } from "@ternary/api-lib/core/commands/CreateScopedViewCommand";
import { CreateSnowflakeIntegrationCommandResult } from "@ternary/api-lib/core/commands/CreateSnowflakeIntegrationCommand";
import { CreateTenantCommandResult } from "@ternary/api-lib/core/commands/CreateTenantCommand";
import { CreateUserGroupConfigCommandResult } from "@ternary/api-lib/core/commands/CreateUserGroupConfigCommand";
import { DeleteApiKeyCommandResult } from "@ternary/api-lib/core/commands/DeleteApiKeyCommand";
import { DeleteBudgetCommandResult } from "@ternary/api-lib/core/commands/DeleteBudgetCommand";
import { DeleteCostCompareBillCommandResult } from "@ternary/api-lib/core/commands/DeleteCostCompareBillCommand";
import { DeleteCustomLabelCommandResult } from "@ternary/api-lib/core/commands/DeleteCustomLabelCommand";
import { DeleteCustomMetricCommandResult } from "@ternary/api-lib/core/commands/DeleteCustomMetricCommand";
import { DeleteDashboardCommandResult } from "@ternary/api-lib/core/commands/DeleteDashboardCommand";
import { DeleteDataIntegrationCommandResult } from "@ternary/api-lib/core/commands/DeleteDataIntegrationCommand";
import { DeleteJiraIntegrationCommandResult } from "@ternary/api-lib/core/commands/DeleteJiraIntegrationCommand";
import { DeleteLabelGroupingRuleCommandResult } from "@ternary/api-lib/core/commands/DeleteLabelGroupingRuleCommand";
import { DeleteMspBillingInfoCommandResult } from "@ternary/api-lib/core/commands/DeleteMspBillingInfoCommand";
import { DeleteMspBillingRuleSetCommandResult } from "@ternary/api-lib/core/commands/DeleteMspBillingRuleSetCommand";
import { DeleteRampPlanCommandResult } from "@ternary/api-lib/core/commands/DeleteRampPlanCommand";
import { DeleteReallocationCommandResult } from "@ternary/api-lib/core/commands/DeleteReallocationCommand";
import { DeleteReportCommandResult } from "@ternary/api-lib/core/commands/DeleteReportCommand";
import { DeleteResourceSubscriptionCommandResult } from "@ternary/api-lib/core/commands/DeleteResourceSubscriptionCommand";
import { DeleteSavingsOpportunityFilterCommandResult } from "@ternary/api-lib/core/commands/DeleteSavingsOpportunityFilterCommand";
import { DeleteScopedViewCommandResult } from "@ternary/api-lib/core/commands/DeleteScopedViewCommand";
import { DeleteSlackIntegrationCommandResult } from "@ternary/api-lib/core/commands/DeleteSlackIntegrationCommand";
import { DeleteUserGroupConfigCommandResult } from "@ternary/api-lib/core/commands/DeleteUserGroupConfigCommand";
import { GenerateAnalyticsTokenCommandResult } from "@ternary/api-lib/core/commands/GenerateAnalyticsTokenCommand";
import { GenerateCostCompareTakeoutReportCommandResult } from "@ternary/api-lib/core/commands/GenerateCostCompareTakeoutReportCommand";
import { GenerateMspBillingInvoicePdfCommandResult } from "@ternary/api-lib/core/commands/GenerateMspBillingInvoicePdfCommand";
import { GenerateMspBillingManifestCsvCommandResult } from "@ternary/api-lib/core/commands/GenerateMspBillingManifestCsvCommand";
import { GenerateReportPDFCommandResult } from "@ternary/api-lib/core/commands/GenerateReportPDFCommand";
import { GenerateZendeskTokenCommandResult } from "@ternary/api-lib/core/commands/GenerateZendeskTokenCommand";
import { GrantUsersGlobalAccessCommandResult } from "@ternary/api-lib/core/commands/GrantUsersGlobalAccessCommand";
import { GrantUsersTenantAccessCommandResult } from "@ternary/api-lib/core/commands/GrantUsersTenantAccessCommand";
import { RemoveFeatureFlagCommandResult } from "@ternary/api-lib/core/commands/RemoveFeatureFlagCommand";
import { RevokeUserGlobalAccessCommandResult } from "@ternary/api-lib/core/commands/RevokeUserGlobalAccessCommand";
import { RevokeUserTenantAccessCommandResult } from "@ternary/api-lib/core/commands/RevokeUserTenantAccessCommand";
import { TriggerReallocationCommandResult } from "@ternary/api-lib/core/commands/TriggerReallocationCommand";
import { UpdateAWSIntegrationCommandResult } from "@ternary/api-lib/core/commands/UpdateAWSIntegrationCommand";
import { UpdateAlertRuleCommandResult } from "@ternary/api-lib/core/commands/UpdateAlertRuleCommand";
import { UpdateAlibabaIntegrationCommandResult } from "@ternary/api-lib/core/commands/UpdateAlibabaIntegrationCommand";
import { UpdateAzureIntegrationCommandResult } from "@ternary/api-lib/core/commands/UpdateAzureIntegrationCommand";
import { UpdateBudgetCommandResult } from "@ternary/api-lib/core/commands/UpdateBudgetCommand";
import { UpdateCaseCommandResult } from "@ternary/api-lib/core/commands/UpdateCaseCommand";
import { UpdateCasesCommandResult } from "@ternary/api-lib/core/commands/UpdateCasesCommand";
import { UpdateCustomLabelCommandResult } from "@ternary/api-lib/core/commands/UpdateCustomLabelCommand";
import { UpdateCustomMetricCommandResult } from "@ternary/api-lib/core/commands/UpdateCustomMetricCommand";
import { UpdateDashboardCommandResult } from "@ternary/api-lib/core/commands/UpdateDashboardCommand";
import { UpdateDashboardsCommandResult } from "@ternary/api-lib/core/commands/UpdateDashboardsCommand";
import { UpdateDimensionPreferencesCommandResult } from "@ternary/api-lib/core/commands/UpdateDimensionPreferencesCommand";
import { UpdateGCPIntegrationCommandResult } from "@ternary/api-lib/core/commands/UpdateGCPIntegrationCommand";
import { UpdateJiraIntegrationCommandResult } from "@ternary/api-lib/core/commands/UpdateJiraIntegrationCommand";
import { UpdateLabelGroupingRuleCommandResult } from "@ternary/api-lib/core/commands/UpdateLabelGroupingRuleCommand";
import { UpdateMeasurePreferencesCommandResult } from "@ternary/api-lib/core/commands/UpdateMeasurePreferencesCommand";
import { UpdateMspBillingInfoCommandResult } from "@ternary/api-lib/core/commands/UpdateMspBillingInfoCommand";
import { UpdateMspBillingInvoiceCommandResult } from "@ternary/api-lib/core/commands/UpdateMspBillingInvoiceCommand";
import { UpdateMspBillingRuleSetCommandResult } from "@ternary/api-lib/core/commands/UpdateMspBillingRuleSetCommand";
import { UpdateMspSharedPayerIntegrationsCommandResult } from "@ternary/api-lib/core/commands/UpdateMspSharedPayerIntegrationsCommand";
import { UpdateMspTenantSettingsCommandResult } from "@ternary/api-lib/core/commands/UpdateMspTenantSettingsCommand";
import { UpdateOracleIntegrationCommandResult } from "@ternary/api-lib/core/commands/UpdateOracleIntegrationCommand";
import { UpdateRampPlanCommandResult } from "@ternary/api-lib/core/commands/UpdateRampPlanCommand";
import { UpdateReallocationCommandResult } from "@ternary/api-lib/core/commands/UpdateReallocationCommand";
import { UpdateRecommendationCommandResult } from "@ternary/api-lib/core/commands/UpdateRecommendationCommand";
import { UpdateRecommendationsCommandResult } from "@ternary/api-lib/core/commands/UpdateRecommendationsCommand";
import { UpdateReportCommandResult } from "@ternary/api-lib/core/commands/UpdateReportCommand";
import { UpdateReportsCommandResult } from "@ternary/api-lib/core/commands/UpdateReportsCommand";
import { UpdateResourceSubscriptionCommandResult } from "@ternary/api-lib/core/commands/UpdateResourceSubscriptionCommand";
import { UpdateSavingsOpportunityFilterCommandResult } from "@ternary/api-lib/core/commands/UpdateSavingsOpportunityFilterCommand";
import { UpdateScopedViewCommandResult } from "@ternary/api-lib/core/commands/UpdateScopedViewCommand";
import { UpdateSlackIntegrationCommandResult } from "@ternary/api-lib/core/commands/UpdateSlackIntegrationCommand";
import { UpdateSnowflakeIntegrationCommandResult } from "@ternary/api-lib/core/commands/UpdateSnowflakeIntegrationCommand";
import { UpdateTenantCommandResult } from "@ternary/api-lib/core/commands/UpdateTenantCommand";
import { UpdateUserCommandResult } from "@ternary/api-lib/core/commands/UpdateUserCommand";
import { UpdateUserGroupConfigCommandResult } from "@ternary/api-lib/core/commands/UpdateUserGroupConfigCommand";
import { UpdateUserTenantRolesCommandResult } from "@ternary/api-lib/core/commands/UpdateUserTenantRolesCommand";
import { UpdateUserTenantSettingsCommandResult } from "@ternary/api-lib/core/commands/UpdateUserTenantSettingsCommand";
import { UploadImageCommandResult } from "@ternary/api-lib/core/commands/UploadImageCommand";
import { ValidateDataIntegrationCommandResult } from "@ternary/api-lib/core/commands/ValidateDataIntegrationCommand";
import { FindAlertRuleEventsQueryResult } from "@ternary/api-lib/core/queries/FindAlertRuleEventsQuery";
import { FindAlertRuleQueryResult } from "@ternary/api-lib/core/queries/FindAlertRuleQuery";
import { FindAlertRulesQueryResult } from "@ternary/api-lib/core/queries/FindAlertRulesQuery";
import { FindApiKeysQueryResult } from "@ternary/api-lib/core/queries/FindApiKeysQuery";
import { FindAwsReservedInstancesRecommendationsQueryResult } from "@ternary/api-lib/core/queries/FindAwsReservedInstancesRecommendationsQuery";
import { FindAwsSavingsPlanRecommendationsQueryResult } from "@ternary/api-lib/core/queries/FindAwsSavingsPlanRecommendationsQuery";
import { FindAzureRateRecommendationsQueryResult } from "@ternary/api-lib/core/queries/FindAzureRateRecommendationsQuery";
import { FindBigQueryMetadataQueryResult } from "@ternary/api-lib/core/queries/FindBigQueryMetadataQuery";
import { FindBudgetsQueryResult } from "@ternary/api-lib/core/queries/FindBudgetsQuery";
import { FindCaseQueryResult } from "@ternary/api-lib/core/queries/FindCaseQuery";
import { FindCasesQueryResult } from "@ternary/api-lib/core/queries/FindCasesQuery";
import { FindCostAlertQueryResult } from "@ternary/api-lib/core/queries/FindCostAlertQuery";
import { FindCostAlertsQueryResult } from "@ternary/api-lib/core/queries/FindCostAlertsQuery";
import { FindCostCompareBillsQueryResult } from "@ternary/api-lib/core/queries/FindCostCompareBillsQuery";
import { FindCudRecommendationsQueryResult } from "@ternary/api-lib/core/queries/FindCudRecommendationsQuery";
import { FindCustomLabelsQueryResult } from "@ternary/api-lib/core/queries/FindCustomLabelsQuery";
import { FindCustomMetricsQueryResult } from "@ternary/api-lib/core/queries/FindCustomMetricsQuery";
import { FindDashboardQueryResult } from "@ternary/api-lib/core/queries/FindDashboardQuery";
import { FindDashboardsQueryResult } from "@ternary/api-lib/core/queries/FindDashboardsQuery";
import { FindDataIntegrationsQueryResult } from "@ternary/api-lib/core/queries/FindDataIntegrationsQuery";
import {
  FindDatadogIntegrationQueryFailure,
  FindDatadogIntegrationQueryResult,
} from "@ternary/api-lib/core/queries/FindDatadogIntegrationQuery";
import { FindDimensionPreferencesQueryResult } from "@ternary/api-lib/core/queries/FindDimensionPreferencesQuery";
import { FindFeatureFlagsQueryResult } from "@ternary/api-lib/core/queries/FindFeatureFlagsQuery";
import { FindGrantsQueryResult } from "@ternary/api-lib/core/queries/FindGrantsQuery";
import {
  FindJiraIntegrationQueryFailure,
  FindJiraIntegrationQueryResult,
} from "@ternary/api-lib/core/queries/FindJiraIntegrationQuery";
import { FindLabelGroupingRulesQueryResult } from "@ternary/api-lib/core/queries/FindLabelGroupingRulesQuery";
import { FindLabelMapsQueryResult } from "@ternary/api-lib/core/queries/FindLabelMapsQuery";
import { FindMeasurePreferencesQueryResult } from "@ternary/api-lib/core/queries/FindMeasurePreferencesQuery";
import { FindMspBillingInvoiceQueryResult } from "@ternary/api-lib/core/queries/FindMspBillingInvoiceQuery";
import { FindMspBillingManifestsQueryResult } from "@ternary/api-lib/core/queries/FindMspBillingManifestsQuery";
import { FindMspBillingRuleSetsQueryResult } from "@ternary/api-lib/core/queries/FindMspBillingRuleSetsQuery";
import { FindMspChildDataIntegrationsQueryResult } from "@ternary/api-lib/core/queries/FindMspChildDataIntegrationsQuery";
import { FindMspChildResourcesQueryResult } from "@ternary/api-lib/core/queries/FindMspChildResourcesQuery";
import { FindMspChildUsersQueryResult } from "@ternary/api-lib/core/queries/FindMspChildUsersQuery";
import { FindMspSharedPayerIntegrationsQueryResult } from "@ternary/api-lib/core/queries/FindMspSharedPayerIntegrationsQuery";
import { FindMspTenantSettingsQueryResult } from "@ternary/api-lib/core/queries/FindMspTenantSettingsQuery";
import { FindPermissionsQueryResult } from "@ternary/api-lib/core/queries/FindPermissionsQuery";
import { FindRampPlansQueryResult } from "@ternary/api-lib/core/queries/FindRampPlansQuery";
import { FindReallocationJobsQueryResult } from "@ternary/api-lib/core/queries/FindReallocationJobsQuery";
import { FindReallocationsQueryResult } from "@ternary/api-lib/core/queries/FindReallocationsQuery";
import { FindRecommendationQueryResult } from "@ternary/api-lib/core/queries/FindRecommendationQuery";
import { FindRecommendationTypeQueryResult } from "@ternary/api-lib/core/queries/FindRecommendationTypeQuery";
import { FindRecommendationTypesQueryResult } from "@ternary/api-lib/core/queries/FindRecommendationTypesQuery";
import { FindRecommendationsQueryResult } from "@ternary/api-lib/core/queries/FindRecommendationsQuery";
import { FindReportQueryResult } from "@ternary/api-lib/core/queries/FindReportQuery";
import { FindReportsQueryResult } from "@ternary/api-lib/core/queries/FindReportsQuery";
import { FindRolesQueryResult } from "@ternary/api-lib/core/queries/FindRolesQuery";
import { FindSavingsOpportunityFiltersQueryResult } from "@ternary/api-lib/core/queries/FindSavingsOpportunityFiltersQuery";
import { FindScopedViewFiltersQueryResult } from "@ternary/api-lib/core/queries/FindScopedViewFiltersQuery";
import { FindScopedViewsQueryResult } from "@ternary/api-lib/core/queries/FindScopedViewsQuery";
import {
  FindSlackIntegrationQueryFailure,
  FindSlackIntegrationQueryResult,
} from "@ternary/api-lib/core/queries/FindSlackIntegrationQuery";
import { FindTenantQueryResult } from "@ternary/api-lib/core/queries/FindTenantQuery";
import { FindTenantsQueryResult } from "@ternary/api-lib/core/queries/FindTenantsQuery";
import { FindUserGroupConfigsQueryResult } from "@ternary/api-lib/core/queries/FindUserGroupConfigsQuery";
import { FindUsersQueryResult } from "@ternary/api-lib/core/queries/FindUsersQuery";
import MeQueryResult from "@ternary/api-lib/core/queries/MeQueryResult";
import { PermissionEntity } from "@ternary/api-lib/core/types";
import { EventReporter } from "@ternary/api-lib/telemetry";
import { chunk } from "lodash";
import HTTPRequestSender from "../HTTPRequestSender";
import CoreAPIClient from "./CoreAPIClient";
import CoreAPIClientError from "./CoreAPIClientError";
import {
  AddFeatureFlagParameters,
  CreateAWSIntegrationParams,
  CreateAlertRuleParameters,
  CreateAlibabaIntegrationParams,
  CreateApiKeyParameters,
  CreateAzureIntegrationParams,
  CreateBillParameters,
  CreateBudgetParameters,
  CreateCaseCommentParams,
  CreateCaseParams,
  CreateCustomLabelParams,
  CreateCustomMetricParams,
  CreateDashboardParameters,
  CreateDimensionPreferencesParams,
  CreateGCPIntegrationParams,
  CreateLabelGroupingRuleParams,
  CreateMeasurePreferencesParams,
  CreateMspBillingInfoParams,
  CreateMspBillingInvoiceParams,
  CreateMspBillingManifestParams,
  CreateMspSharedPayerIntegrationsParams,
  CreateOracleIntegrationParams,
  CreateRampPlanParameters,
  CreateReallocationParameters,
  CreateReportParameters,
  CreateResourceSubscriptionParams,
  CreateRuleSetParams,
  CreateSavingsOpportunityFilterParameters,
  CreateScopedViewParameters,
  CreateSnowFlakeIntegrationParams,
  CreateTenantParams,
  CreateUserGroupConfigParameters,
  GenerateTakeoutReportParameters,
  GetBigQueryMetadataParams,
  GetRecommendationsParams,
  GrantUsersMspAccessParams,
  GrantUsersTenantAccessParams,
  LabelMap,
  RemoveFeatureFlagParameters,
  RevokeUserMspAccessParams,
  RevokeUserTenantAccessParams,
  UpdateAWSIntegrationParams,
  UpdateAlertRuleParameters,
  UpdateAlibabaIntegrationParams,
  UpdateAzureIntegrationParams,
  UpdateBudgetParameters,
  UpdateCaseParams,
  UpdateCasesParams,
  UpdateCustomLabelParams,
  UpdateCustomMetricParams,
  UpdateDashboardParameters,
  UpdateDashboardsParameters,
  UpdateDimensionPreferencesParams,
  UpdateGCPIntegrationParams,
  UpdateJiraIntegrationParams,
  UpdateLabelGroupingRuleParams,
  UpdateMeasurePreferencesParams,
  UpdateMspBillingInfoParams,
  UpdateMspBillingInvoiceParams,
  UpdateMspSharedPayerIntegrationsParams,
  UpdateMspTenantSettingsParams,
  UpdateOracleIntegrationParams,
  UpdateRampPlanParams,
  UpdateReallocationParameters,
  UpdateRecommendationParameters,
  UpdateRecommendationsParameters,
  UpdateReportParameters,
  UpdateReportsParameters,
  UpdateResourceSubscriptionParams,
  UpdateRuleSetParams,
  UpdateSavingsOpportunityFilterParameters,
  UpdateScopedViewParameters,
  UpdateSlackIntegrationParams,
  UpdateSnowflakeIntegrationParams,
  UpdateTenantParams,
  UpdateUserGroupConfigParameters,
  UpdateUserParams,
  UpdateUserTenantRolesParams,
  UpdateUserTenantSettingParams,
  UploadImageParams,
} from "./types";

interface Config {
  eventReporter: EventReporter;
  sender: HTTPRequestSender;
}

export default class CoreAPIClientImpl implements CoreAPIClient {
  private readonly _eventReporter: EventReporter;
  private readonly _sender: HTTPRequestSender;

  constructor({ eventReporter, sender }: Config) {
    this._eventReporter = eventReporter;
    this._sender = sender;
  }

  public async addFeatureFlag(
    params: AddFeatureFlagParameters
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/feature-flags`, {
        method: "POST",
        body: params,
      })) as AddFeatureFlagCommandResult;

      return result.flag;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_ADDING_FEATURE_FLAG
      );
    }
  }

  public async createAlertRule(
    params: CreateAlertRuleParameters
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/alert-rules`, {
        method: "POST",
        body: params,
      })) as CreateAlertRuleCommandResult;
      return result.ruleID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_CREATING_ALERT_RULE
      );
    }
  }

  public async createAlibabaIntegration(
    params: CreateAlibabaIntegrationParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/data-integrations/alibaba`, {
        method: "POST",
        body: params,
      })) as CreateAlibabaIntegrationCommandResult;

      return result.integrationID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_CREATING_ALIBABA_INTEGRATION
      );
    }
  }

  public async createApiKey(
    params: CreateApiKeyParameters
  ): Promise<{ apiKeyID: string; secret: string }> {
    try {
      const result = (await this._sender.send(`/api-keys`, {
        method: "POST",
        body: params,
      })) as CreateApiKeyCommandResult;

      return result;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_CREATING_API_KEY);
    }
  }

  public async createAWSIntegration(
    params: CreateAWSIntegrationParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/data-integrations/aws`, {
        method: "POST",
        body: params,
      })) as CreateAWSIntegrationCommandResult;

      return result.integrationID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_CREATING_AWS_INTEGRATION
      );
    }
  }

  public async createAzureIntegration(
    params: CreateAzureIntegrationParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/data-integrations/azure`, {
        method: "POST",
        body: params,
      })) as CreateAzureIntegrationCommandResult;

      return result.integrationID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_CREATING_AZURE_INTEGRATION
      );
    }
  }

  public async createBill(params: CreateBillParameters): Promise<string> {
    try {
      const result = (await this._sender.send("/cost-compare-bills", {
        method: "POST",
        body: params,
      })) as CreateCostCompareBillCommandResult;

      return result.billID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_CREATING_BILL);
    }
  }

  public async createBudget(params: CreateBudgetParameters): Promise<string> {
    try {
      const result = (await this._sender.send("/budgets", {
        method: "POST",
        body: params,
      })) as CreateBudgetCommandResult;

      return result.budgetID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_CREATING_BUDGET);
    }
  }

  public async createCaseComment(
    params: CreateCaseCommentParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/cases/${params.caseID}/comments`,
        { method: "POST", body: params }
      )) as CreateCaseCommentCommandResult;

      return result.commentID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_CREATING_CASE_COMMENT
      );
    }
  }

  public async createCase(params: CreateCaseParams): Promise<string> {
    try {
      const result = (await this._sender.send("/cases", {
        method: "POST",
        body: params,
      })) as CreateCaseCommandResult;

      return result.caseID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_CREATING_CASE);
    }
  }

  public async createCustomLabel(
    params: CreateCustomLabelParams
  ): Promise<string> {
    try {
      // NOTE: Endpoint takes an array of labels for bulk creaton,
      // but UI only allows for creation of one label at a time.
      const body = {
        tenantID: params.tenantID,
        labels: [
          {
            matchers: params.matchers,
            outputs: params.outputs,
          },
        ],
      };

      const result = (await this._sender.send("/custom-labels", {
        method: "POST",
        body,
      })) as CreateCustomLabelsCommandResult;

      return result.customLabelIDs[0];
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_CREATING_CUSTOM_LABEL
      );
    }
  }

  public async createCustomMetric(
    params: CreateCustomMetricParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/custom-metrics`, {
        method: "POST",
        body: params,
      })) as CreateCustomMetricCommandResult;

      return result.customMetricID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_CREATING_CUSTOM_METRIC
      );
    }
  }
  public async createDashboard(
    params: CreateDashboardParameters
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/dashboards`, {
        method: "POST",
        body: params,
      })) as CreateDashboardCommandResult;

      return result.dashboardID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_CREATING_DASHBOARD
      );
    }
  }

  public async createGCPIntegration(
    params: CreateGCPIntegrationParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/data-integrations/gcp`, {
        method: "POST",
        body: params,
      })) as CreateGCPIntegrationCommandResult;

      return result.integrationID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_CREATING_GCP_INTEGRATION
      );
    }
  }

  public async createLabelGroupingRule(
    params: CreateLabelGroupingRuleParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send("/label-grouping-rules", {
        method: "POST",
        body: params,
      })) as CreateLabelGroupingRuleCommandResult;

      return result.labelGroupingRuleID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_CREATING_LABEL_GROUPING_RULE
      );
    }
  }

  public async createOracleIntegration(
    params: CreateOracleIntegrationParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/data-integrations/oracle`, {
        method: "POST",
        body: params,
      })) as CreateOracleIntegrationCommandResult;

      return result.integrationID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_CREATING_ORACLE_INTEGRATION
      );
    }
  }

  public async createDimensionPreferences(
    params: CreateDimensionPreferencesParams
  ): Promise<string[]> {
    try {
      const result = (await this._sender.send(`/dimension-preferences`, {
        method: "POST",
        body: params,
      })) as CreateDimensionPreferencesCommandResult;

      return result.preferenceIDs;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_CREATING_LABEL_PREFERENCES
      );
    }
  }

  public async createMspBillingInvoice(
    params: CreateMspBillingInvoiceParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/msp-billing-invoice`, {
        method: "POST",
        body: params,
      })) as CreateMspBillingInvoiceCommandResult;

      return result.invoiceID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_CREATING_MSP_BILLING_INVOICE
      );
    }
  }

  public async createMspBillingManifest(
    params: CreateMspBillingManifestParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/msp-billing-manifests`, {
        method: "POST",
        body: params,
      })) as CreateMspBillingManifestCommandResult;

      return result.manifestID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_CREATING_MSP_BILLING_MANIFEST
      );
    }
  }

  public async createMeasurePreferences(
    params: CreateMeasurePreferencesParams
  ): Promise<string[]> {
    try {
      const result = (await this._sender.send(`/measure-preferences`, {
        method: "POST",
        body: params,
      })) as CreateMeasurePreferencesCommandResult;

      return result.preferenceIDs;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_CREATING_MEASURE_PREFERENCES
      );
    }
  }

  public async createMspBillingInfo(
    params: CreateMspBillingInfoParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/msp-billing-info`, {
        method: "POST",
        body: params,
      })) as CreateMspBillingInfoCommandResult;

      return result.billingInfoID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_CREATING_MSP_BILLING_INFORMATION
      );
    }
  }

  public async createMspSharedPayerIntegrations(
    params: CreateMspSharedPayerIntegrationsParams
  ): Promise<string[]> {
    try {
      const result = (await this._sender.send(
        `/msp-shared-payer-data-integrations`,
        {
          method: "POST",
          body: params,
        }
      )) as CreateMspSharedPayerIntegrationsCommandResult;

      return result.integrationIDs;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_CREATING_MSP_SHARED_PAYER_INTEGRATIONS
      );
    }
  }

  public async createRampPlan(
    params: CreateRampPlanParameters
  ): Promise<string> {
    try {
      const body = {
        ...params,
        key: params.enumeratedValues.length > 0 ? "ternary.app/category" : "",
      };

      const result = (await this._sender.send(
        `/ramp-plans?tenantID=${params.tenantID}`,
        { method: "POST", body }
      )) as CreateRampPlanCommandResult;

      return result.rampPlanID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_CREATING_RAMP_PLAN
      );
    }
  }

  public async createReallocation(
    params: CreateReallocationParameters
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/reallocations`, {
        method: "POST",
        body: params,
      })) as CreateReallocationCommandResult;

      return result.reallocationID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_CREATING_REALLOCATION
      );
    }
  }

  public async createReport(params: CreateReportParameters): Promise<string> {
    try {
      const result = (await this._sender.send(`/reports`, {
        method: "POST",
        body: params,
      })) as CreateReportCommandResult;

      return result.reportID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_CREATING_REPORT);
    }
  }

  public async createResourceSubscription(
    params: CreateResourceSubscriptionParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/resource-subscriptions`, {
        method: "POST",
        body: params,
      })) as CreateResourceSubscriptionCommandResult;

      return result.subscriptionID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_CREATING_REPORT_SUBSCRIPTION
      );
    }
  }

  public async createRuleSet(params: CreateRuleSetParams): Promise<string> {
    try {
      const result = (await this._sender.send(`/msp-rule-sets`, {
        method: "POST",
        body: params,
      })) as CreateMspBillingRuleSetCommandResult;

      return result.ruleSetID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_CREATING_RULE_SET);
    }
  }

  public async createSavingsOpportunityFilter(
    params: CreateSavingsOpportunityFilterParameters
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/savings-opportunity-filter`, {
        method: "POST",
        body: params,
      })) as CreateSavingsOpportunityFilterCommandResult;

      return result.savingOpportunityFilterID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_CREATING_SAVINGS_OPPORTUNITY_FILTER
      );
    }
  }

  public async createScopedView(
    params: CreateScopedViewParameters
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/scoped-views`, {
        method: "POST",
        body: params,
      })) as CreateScopedViewCommandResult;

      return result.scopedViewID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_CREATING_SCOPED_VIEW
      );
    }
  }

  public async createSnowflakeIntegration(
    params: CreateSnowFlakeIntegrationParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/data-integrations/snowflake`, {
        method: "POST",
        body: params,
      })) as CreateSnowflakeIntegrationCommandResult;

      return result.integrationID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_CREATING_SNOWFLAKE_INTEGRATION
      );
    }
  }

  public async createTenant(params: CreateTenantParams): Promise<string> {
    try {
      const result = (await this._sender.send("/tenants", {
        method: "POST",
        body: params,
      })) as CreateTenantCommandResult;

      return result.tenantID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_CREATING_TENANT);
    }
  }

  public async createUserGroupConfig(
    params: CreateUserGroupConfigParameters
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/user-group-configs`, {
        method: "POST",
        body: params,
      })) as CreateUserGroupConfigCommandResult;

      return result.userGroupConfigID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_CREATING_USER_GROUP_CONFIG
      );
    }
  }

  public async deleteApiKey(apiKeyID: string): Promise<string> {
    try {
      const result = (await this._sender.send(`/api-keys/${apiKeyID}`, {
        method: "DELETE",
      })) as DeleteApiKeyCommandResult;

      return result.apiKeyID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_DELETING_API_KEY);
    }
  }

  public async deleteBill(billID: string): Promise<string> {
    try {
      const result = (await this._sender.send(`/cost-compare-bills/${billID}`, {
        method: "DELETE",
      })) as DeleteCostCompareBillCommandResult;

      return result.billID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_DELETING_BILL);
    }
  }

  public async deleteBudget(budgetID: string): Promise<string> {
    try {
      const result = (await this._sender.send(`/budgets/${budgetID}`, {
        method: "DELETE",
      })) as DeleteBudgetCommandResult;

      return result.budgetID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_DELETING_BUDGET);
    }
  }

  public async deleteCustomLabel(customLabelID: string): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/custom-labels/${customLabelID}`,
        { method: "DELETE" }
      )) as DeleteCustomLabelCommandResult;

      return result.customLabelID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_DELETING_CUSTOM_LABEL
      );
    }
  }

  public async deleteCustomMetric(customMetricID: string): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/custom-metrics/${customMetricID}`,
        { method: "DELETE" }
      )) as DeleteCustomMetricCommandResult;

      return result.customMetricID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_DELETING_CUSTOM_METRIC
      );
    }
  }

  public async deleteDashboard(dashboardID: string): Promise<string> {
    try {
      const result = (await this._sender.send(`/dashboards/${dashboardID}`, {
        method: "DELETE",
      })) as DeleteDashboardCommandResult;

      return result.dashboardID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_DELETING_DASHBOARD
      );
    }
  }

  // NOTE: Reusing this for all data integrations for now
  public async deleteDataIntegration(
    integrationID: string,
    providerType: CloudProviderType
  ): Promise<string> {
    try {
      let provider = "";

      switch (providerType) {
        case CloudProviderType.ALIBABA:
          provider = "alibaba";
          break;
        case CloudProviderType.AWS:
          provider = "aws";
          break;
        case CloudProviderType.AZURE:
          provider = "azure";
          break;
        case CloudProviderType.GCP:
          provider = "gcp";
          break;
        case CloudProviderType.OCI:
          provider = "oracle";
          break;
        case CloudProviderType.SNOWFLAKE:
          provider = "snowflake";
          break;
      }

      const result = (await this._sender.send(
        `/data-integrations/${provider}/${integrationID}`,
        { method: "DELETE" }
      )) as DeleteDataIntegrationCommandResult;

      return result.integrationID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_DELETING_DATA_INTEGRATION
      );
    }
  }

  public async deleteJiraIntegration(tenantID: string): Promise<string> {
    try {
      const result = (await this._sender.send(`/integrations/jira`, {
        method: "DELETE",
        body: { tenantID },
      })) as DeleteJiraIntegrationCommandResult;

      return result.integrationID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_DELETING_JIRA_INTEGRATION
      );
    }
  }

  public async deleteLabelGroupingRule(
    labelGroupingRuleID: string
  ): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/label-grouping-rules/${labelGroupingRuleID}`,
        { method: "DELETE" }
      )) as DeleteLabelGroupingRuleCommandResult;

      return result.labelGroupingRuleID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_DELETING_LABEL_GROUPING_RULE
      );
    }
  }

  public async deleteMspBillingInfo(billingInfoID: string): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/msp-billing-info/${billingInfoID}`,
        { method: "DELETE" }
      )) as DeleteMspBillingInfoCommandResult;

      return result.billingInfoID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_DELETING_MSP_BILLING_INFORMATION
      );
    }
  }

  public async deleteSlackIntegration(tenantID: string): Promise<string> {
    try {
      const result = (await this._sender.send(`/integrations/slack`, {
        method: "DELETE",
        body: { tenantID },
      })) as DeleteSlackIntegrationCommandResult;

      return result.integrationID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_DELETING_SLACK_INTEGRATION
      );
    }
  }

  public async deleteRampPlan(rampPlanID: string): Promise<string> {
    try {
      const result = (await this._sender.send(`/ramp-plans/${rampPlanID}`, {
        method: "DELETE",
      })) as DeleteRampPlanCommandResult;

      return result.rampPlanID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_DELETING_RAMP_PLAN
      );
    }
  }

  public async deleteReallocation(reallocationID: string): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/reallocations/${reallocationID}`,
        { method: "DELETE" }
      )) as DeleteReallocationCommandResult;

      return result.reallocationID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_DELETING_REALLOCATION
      );
    }
  }

  public async deleteReport(reportID: string): Promise<string> {
    try {
      const result = (await this._sender.send(`/reports/${reportID}`, {
        method: "DELETE",
      })) as DeleteReportCommandResult;

      return result.reportID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_DELETING_REPORT);
    }
  }

  public async deleteResourceSubscription(
    subscriptionID: string
  ): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/resource-subscriptions/${subscriptionID}`,
        {
          method: "DELETE",
        }
      )) as DeleteResourceSubscriptionCommandResult;

      return result.subscriptionID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_DELETING_REPORT_SUBSCRIPTION
      );
    }
  }

  public async deleteRuleSet(ruleSetID: string): Promise<string> {
    try {
      const result = (await this._sender.send(`/msp-rule-sets/${ruleSetID}`, {
        method: "DELETE",
      })) as DeleteMspBillingRuleSetCommandResult;

      return result.ruleSetID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_DELETING_RULE_SET);
    }
  }

  public async deleteSavingsOpportunityFilter(
    filterID: string
  ): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/savings-opportunity-filter/${filterID}`,
        { method: "DELETE" }
      )) as DeleteSavingsOpportunityFilterCommandResult;

      return result.filterID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_DELETING_SAVING_OPPORTUNITY_FILTER
      );
    }
  }

  public async deleteScopedView(scopedViewID: string): Promise<string> {
    try {
      const result = (await this._sender.send(`/scoped-views/${scopedViewID}`, {
        method: "DELETE",
      })) as DeleteScopedViewCommandResult;

      return result.scopedViewID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_DELETING_SCOPED_VIEW
      );
    }
  }

  public async deleteUserGroupConfig(
    userGroupConfigID: string
  ): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/user-group-configs/${userGroupConfigID}`,
        { method: "DELETE" }
      )) as DeleteUserGroupConfigCommandResult;

      return result.userGroupConfigID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_DELETING_USER_GROUP_CONFIG
      );
    }
  }

  public async generateAnalyticsToken(tenantID: string): Promise<string> {
    try {
      const result = (await this._sender.send(`/analytics/generate-token`, {
        method: "POST",
        body: { tenantID },
      })) as GenerateAnalyticsTokenCommandResult;

      return result.token;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_GENERATING_ANALYTICS_TOKEN
      );
    }
  }

  public async generateMspBillingInvoicePdf(
    manifestID: string
  ): Promise<GenerateMspBillingInvoicePdfCommandResult["pdf"]> {
    try {
      const result = (await this._sender.send(
        `/msp-billing-manifests/${manifestID}/pdf`,
        { method: "POST" }
      )) as GenerateMspBillingInvoicePdfCommandResult;

      return result.pdf;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_MSP_BILLING_MANIFEST
      );
    }
  }

  public async generateReportPDF(
    tenantID: string,
    reportID: string
  ): Promise<GenerateReportPDFCommandResult["pdf"]> {
    try {
      const result = (await this._sender.send(
        `/reports/${reportID}/pdf?tenantID=${tenantID}`,
        { method: "POST" }
      )) as GenerateReportPDFCommandResult;

      return result.pdf;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_LOADING_REPORT);
    }
  }

  public async generateTakeoutReport(
    params: GenerateTakeoutReportParameters
  ): Promise<GenerateCostCompareTakeoutReportCommandResult["takeoutReport"]> {
    try {
      const result = (await this._sender.send(
        `/cost-compare-bills/${params.billID}/generate-report`,
        { method: "POST", body: params }
      )) as GenerateCostCompareTakeoutReportCommandResult;

      // NOTE: Request is fast, timeout allows us to render the loader
      await new Promise((resolve) => {
        setTimeout(() => resolve(true), 2000);
      });

      return result.takeoutReport;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_GENERATING_TAKEOUT_REPORT
      );
    }
  }

  public async generateZendeskToken(): Promise<
    GenerateZendeskTokenCommandResult["token"]
  > {
    try {
      const result = (await this._sender.send(`/zendesk-token`, {
        method: "POST",
      })) as GenerateZendeskTokenCommandResult;

      return result.token;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_GENERATING_ZENDESK_TOKEN
      );
    }
  }

  public async getAlertRuleByID(
    alertRuleID: string,
    includeEvents?: boolean
  ): Promise<FindAlertRuleQueryResult["alertRule"]> {
    try {
      const result = (await this._sender.send(
        `/alert-rules/${alertRuleID}?includeEvents=${includeEvents ? true : false}`
      )) as FindAlertRuleQueryResult;

      return result.alertRule;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_ALERT_RULES
      );
    }
  }

  public async getAlertRulesByTenantID(
    tenantID: string
  ): Promise<FindAlertRulesQueryResult["alertRules"]> {
    try {
      const result = (await this._sender.send(
        `/alert-rules?tenantID=${tenantID}`
      )) as FindAlertRulesQueryResult;

      return result.alertRules;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_ALERT_RULES
      );
    }
  }

  public async getAlertRuleEventsByAlertRuleID(
    alertRuleID: string
  ): Promise<FindAlertRuleEventsQueryResult["events"]> {
    try {
      const result = (await this._sender.send(
        `/alert-rule-events/${alertRuleID}`
      )) as FindAlertRuleEventsQueryResult;

      return result.events;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_ALERT_RULE_EVENTS
      );
    }
  }

  public async getApiKeys(): Promise<FindApiKeysQueryResult["apiKeys"]> {
    try {
      const result = (await this._sender.send(
        `/api-keys`
      )) as FindApiKeysQueryResult;

      return result.apiKeys;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_LOADING_API_KEYS);
    }
  }

  public async getAuthenticatedUser(): Promise<MeQueryResult["user"]> {
    try {
      const result = (await this._sender.send("/me")) as MeQueryResult;

      return result.user;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_AUTHENTICATED_USER
      );
    }
  }

  public async getAWSReservedInstanceRecommendations(
    tenantID: string
  ): Promise<
    FindAwsReservedInstancesRecommendationsQueryResult["reservedInstanceRecommendations"]
  > {
    try {
      const result = (await this._sender.send(
        `/aws-rate-recommendations/reserved_instance?tenantID=${tenantID}`
      )) as FindAwsReservedInstancesRecommendationsQueryResult;

      return result.reservedInstanceRecommendations;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_AWS_COMMITTED_USE_RECOMMENDATIONS
      );
    }
  }
  public async getAWSSavingsPlanRecommendations(
    tenantID: string
  ): Promise<
    FindAwsSavingsPlanRecommendationsQueryResult["savingsPlanRecommendations"]
  > {
    try {
      const result = (await this._sender.send(
        `/aws-rate-recommendations/savings_plan?tenantID=${tenantID}`
      )) as FindAwsSavingsPlanRecommendationsQueryResult;

      return result.savingsPlanRecommendations;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_AWS_COMMITTED_USE_RECOMMENDATIONS
      );
    }
  }

  public async getAzureCommittedUseRecommendations(
    tenantID: string
  ): Promise<FindAzureRateRecommendationsQueryResult["recommendations"]> {
    try {
      const result = (await this._sender.send(
        `/azure-rate-recommendations?tenantID=${tenantID}`
      )) as FindAzureRateRecommendationsQueryResult;

      return result.recommendations;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_AWS_COMMITTED_USE_RECOMMENDATIONS
      );
    }
  }

  public async getBigQueryMetadata(
    params: GetBigQueryMetadataParams
  ): Promise<FindBigQueryMetadataQueryResult["metadata"]> {
    try {
      const result = (await this._sender.send(`/big-query-metadata`, {
        method: "POST",
        body: params,
      })) as FindBigQueryMetadataQueryResult;

      return result.metadata;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_BIG_QUERY_METADATA
      );
    }
  }

  public async getBillsByTenantID(
    tenantID: string
  ): Promise<FindCostCompareBillsQueryResult["bills"]> {
    try {
      const result = (await this._sender.send(
        `/cost-compare-bills?tenantID=${tenantID}`
      )) as FindCostCompareBillsQueryResult;

      return result.bills;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_LOADING_BILLS);
    }
  }

  public async getBudgetsByTenantID(
    tenantID: string
  ): Promise<FindBudgetsQueryResult["budgets"]> {
    try {
      const result = (await this._sender.send(
        `/budgets?tenantID=${tenantID}`
      )) as FindBudgetsQueryResult;

      return result.budgets;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_LOADING_BUDGETS);
    }
  }

  public async getCaseByID(
    caseID: string
  ): Promise<FindCaseQueryResult["case"]> {
    try {
      const result = (await this._sender.send(
        `/cases/${caseID}`
      )) as FindCaseQueryResult;

      return result.case;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_LOADING_CASE);
    }
  }

  public async getCasesByTenantID(
    tenantID: string
  ): Promise<FindCasesQueryResult["cases"]> {
    try {
      const result = (await this._sender.send(
        `/cases?tenantID=${tenantID}`
      )) as FindCasesQueryResult;

      return result.cases;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_LOADING_CASES);
    }
  }
  public async getCostAlertByID(
    costAlertID: string
  ): Promise<FindCostAlertQueryResult["costAlert"]> {
    try {
      const result = (await this._sender.send(
        `/cost-alerts/${costAlertID}`
      )) as FindCostAlertQueryResult;

      return result.costAlert;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_COST_ALERT
      );
    }
  }

  public async getCostAlertsByAlertRuleID(
    alertRuleID: string
  ): Promise<FindCostAlertsQueryResult["costAlerts"]> {
    try {
      const result = (await this._sender.send(
        `/cost-alerts?alertRuleID=${alertRuleID}`
      )) as FindCostAlertsQueryResult;

      return result.costAlerts;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_COST_ALERTS
      );
    }
  }

  public async getCostAlertsByTenantID(
    tenantID: string
  ): Promise<FindCostAlertsQueryResult["costAlerts"]> {
    try {
      const result = (await this._sender.send(
        `/cost-alerts?tenantID=${tenantID}`
      )) as FindCostAlertsQueryResult;

      return result.costAlerts;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_COST_ALERTS
      );
    }
  }

  public async getCudRecommendationsByTenantID(
    tenantID: string
  ): Promise<FindCudRecommendationsQueryResult["recommendations"]> {
    try {
      const result = (await this._sender.send(
        `/cud-recommendations?tenantID=${tenantID}`
      )) as FindCudRecommendationsQueryResult;

      return result.recommendations;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_CUD_RECOMMENDATIONS
      );
    }
  }

  public async getCustomLabelsByTenantID(
    tenantID: string
  ): Promise<FindCustomLabelsQueryResult["customLabels"]> {
    try {
      const result = (await this._sender.send(
        `/custom-labels?tenantID=${tenantID}`
      )) as FindCustomLabelsQueryResult;

      return result.customLabels;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_CUSTOM_LABELS
      );
    }
  }

  public async getCustomMetricsByTenantID(
    tenantID: string
  ): Promise<FindCustomMetricsQueryResult["customMetrics"]> {
    try {
      const result = (await this._sender.send(
        `/custom-metrics?tenantID=${tenantID}`
      )) as FindCustomMetricsQueryResult;

      return result.customMetrics;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_CUSTOM_METRICS
      );
    }
  }

  public async getDashboardByID(
    dashboardID: string
  ): Promise<FindDashboardQueryResult["dashboard"]> {
    try {
      const result = (await this._sender.send(
        `/dashboards/${dashboardID}`
      )) as FindDashboardQueryResult;

      return result.dashboard;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_LOADING_DASHBOARD);
    }
  }

  public async getDashboardsByTenantID(
    tenantID: string
  ): Promise<FindDashboardsQueryResult["dashboards"]> {
    try {
      const result = (await this._sender.send(
        `/dashboards?tenantID=${tenantID}`
      )) as FindDashboardsQueryResult;

      return result.dashboards;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_DASHBOARDS
      );
    }
  }

  public async getDatadogIntegrationByTenantID(
    tenantID: string
  ): Promise<FindDatadogIntegrationQueryResult["integration"]> {
    try {
      const result = (await this._sender.send(
        `/integrations/datadog?tenantID=${tenantID}`
      )) as FindDatadogIntegrationQueryResult;

      return result.integration;
    } catch (error) {
      let reason;

      if (
        error instanceof Error &&
        FindDatadogIntegrationQueryFailure.REASON_INTEGRATION_NOT_FOUND.includes(
          error.message
        )
      ) {
        reason = "NOT_FOUND";
      } else {
        this._eventReporter.reportError(error);
      }

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_DATADOG_INTEGRATION,
        { reason }
      );
    }
  }

  public async getDataIntegrationsByTenantID(
    tenantID: string
  ): Promise<FindDataIntegrationsQueryResult["integrations"]> {
    try {
      const result = (await this._sender.send(
        `/data-integrations?tenantID=${tenantID}`
      )) as FindDataIntegrationsQueryResult;

      return result.integrations;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_DATA_INTEGRATIONS
      );
    }
  }

  public async getFeatureFlagsByTenantID(
    tenantID: string
  ): Promise<FindFeatureFlagsQueryResult["featureFlags"]> {
    try {
      const result = (await this._sender.send(
        `/feature-flags?tenantID=${tenantID}`
      )) as FindFeatureFlagsQueryResult;

      return result.featureFlags;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_FEATURE_FLAGS
      );
    }
  }

  public async getGlobalGrantsByParentTenantID(
    parentTenantID: string
  ): Promise<FindGrantsQueryResult["grants"]> {
    try {
      const result = (await this._sender.send(
        `/grants?parentTenantID=${parentTenantID}`
      )) as FindGrantsQueryResult;

      return result.grants;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_LOADING_GRANTS);
    }
  }

  public async getGrantsByTenantID(
    tenantID: string
  ): Promise<FindGrantsQueryResult["grants"]> {
    try {
      const result = (await this._sender.send(
        `/grants?tenantID=${tenantID}`
      )) as FindGrantsQueryResult;

      return result.grants;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_LOADING_GRANTS);
    }
  }

  public async getJiraIntegrationByTenantID(
    tenantID: string
  ): Promise<FindJiraIntegrationQueryResult["integration"]> {
    try {
      const result = (await this._sender.send(
        `/integrations/jira?tenantID=${tenantID}`
      )) as FindJiraIntegrationQueryResult;

      return result.integration;
    } catch (error) {
      let reason;

      if (
        error instanceof Error &&
        FindJiraIntegrationQueryFailure.REASON_INTEGRATION_NOT_FOUND.includes(
          error.message
        )
      ) {
        reason = "NOT_FOUND";
      } else {
        this._eventReporter.reportError(error);
      }

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_JIRA_INTEGRATION,
        { reason }
      );
    }
  }

  public async getLabelGroupingRulesByTenantID(
    tenantID: string
  ): Promise<FindLabelGroupingRulesQueryResult["groupingRules"]> {
    try {
      const result = (await this._sender.send(
        `/label-grouping-rules?tenantID=${tenantID}`
      )) as FindLabelGroupingRulesQueryResult;

      return result.groupingRules;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_LABEL_GROUPING_RULES
      );
    }
  }

  public async getLabelMapsByTenantID(
    tenantID: string
  ): Promise<FindLabelMapsQueryResult["labelMaps"]> {
    try {
      const result = (await this._sender.send(
        `/label-maps?tenantID=${tenantID}`
      )) as FindLabelMapsQueryResult;

      // NOTE: We remove duplicate custom labels that conflict with default dimensions.
      // TODO: Introduce unique IDs so we can differentiate between them.
      return Object.keys(result.labelMaps).reduce(
        (accum: FindLabelMapsQueryResult["labelMaps"], dataSource) => {
          const labelMap: LabelMap = result.labelMaps[dataSource];

          const defaultDimensions = defaultDimensionsMap[dataSource];

          const filteredMap = Object.entries(labelMap).reduce(
            (accum: { [key: string]: string }, [key, value]) =>
              defaultDimensions.includes(key)
                ? accum
                : { ...accum, [key]: value },
            {}
          );

          return { ...accum, [dataSource]: filteredMap };
        },
        result.labelMaps
      );
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_LABEL_MAPS
      );
    }
  }

  public async generateMspBillingManifestCsv(
    manifestID: string
  ): Promise<GenerateMspBillingManifestCsvCommandResult["csv"]> {
    try {
      const result = (await this._sender.send(
        `/msp-billing-manifests/${manifestID}`,
        {
          method: "POST",
        }
      )) as GenerateMspBillingManifestCsvCommandResult;

      return result.csv;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_MSP_BILLING_MANIFEST
      );
    }
  }

  public async getMspBillingManifestsByTenantID(
    tenantID: string
  ): Promise<FindMspBillingManifestsQueryResult["manifests"]> {
    try {
      const result = (await this._sender.send(
        `/msp-billing-manifests?tenantID=${tenantID}`
      )) as FindMspBillingManifestsQueryResult;

      return result.manifests;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_MSP_BILLING_MANIFESTS
      );
    }
  }

  public async getMspBillingInvoiceByTenantID(
    tenantID: string
  ): Promise<FindMspBillingInvoiceQueryResult["invoice"]> {
    try {
      const result = (await this._sender.send(
        `/msp-billing-invoice?tenantID=${tenantID}`
      )) as FindMspBillingInvoiceQueryResult;

      return result.invoice;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_MSP_BILLING_MANIFESTS
      );
    }
  }

  public async getMspChildDataIntegrationsByParentTenantID(
    tenantID: string
  ): Promise<FindMspChildDataIntegrationsQueryResult["integrations"]> {
    try {
      const result = (await this._sender.send(
        `/msp-child-data-integrations?tenantID=${tenantID}`
      )) as FindMspChildDataIntegrationsQueryResult;

      return result.integrations;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_MSP_CHILD_DATA_INTEGRATIONS
      );
    }
  }

  public async getMspSharedPayerIntegrationsByParentTenantID(
    tenantID: string
  ): Promise<FindMspSharedPayerIntegrationsQueryResult["integrations"]> {
    try {
      const result = (await this._sender.send(
        `/msp-shared-payer-data-integrations?tenantID=${tenantID}`
      )) as FindMspSharedPayerIntegrationsQueryResult;

      return result.integrations;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_MSP_CHILD_DATA_INTEGRATIONS
      );
    }
  }

  public async getMspChildResourcesByParentTenantID(
    tenantID: string
  ): Promise<FindMspChildResourcesQueryResult["resources"]> {
    try {
      const result = (await this._sender.send(
        `/msp-child-resources?tenantID=${tenantID}`
      )) as FindMspChildResourcesQueryResult;

      return result.resources;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_MSP_CHILD_RESOURCES
      );
    }
  }

  public async getMspChildUsersByParentTenantID(
    tenantID: string
  ): Promise<FindMspChildUsersQueryResult["users"]> {
    try {
      const result = (await this._sender.send(
        `/msp-child-users?tenantID=${tenantID}`
      )) as FindMspChildUsersQueryResult;

      return result.users;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_MSP_CHILD_USERS
      );
    }
  }

  public async getPermissionsByTenantID(
    tenantID: string
  ): Promise<PermissionEntity[]> {
    try {
      const result = (await this._sender.send(
        `/permissions?tenantID=${tenantID}`
      )) as FindPermissionsQueryResult;

      return result.permissions;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_PERMISSIONS
      );
    }
  }

  public async getDimensionPreferencesByTenantID(
    tenantID: string
  ): Promise<FindDimensionPreferencesQueryResult["preferences"]> {
    try {
      const result = (await this._sender.send(
        `/dimension-preferences?tenantID=${tenantID}`
      )) as FindDimensionPreferencesQueryResult;

      return result.preferences;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_LABEL_PREFERENCES
      );
    }
  }

  public async getMeasurePreferencesByTenantID(
    tenantID: string
  ): Promise<FindMeasurePreferencesQueryResult["preferences"]> {
    try {
      const result = (await this._sender.send(
        `/measure-preferences?tenantID=${tenantID}`
      )) as FindMeasurePreferencesQueryResult;

      return result.preferences;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_MEASURE_PREFERENCES
      );
    }
  }

  public async getMspTenantSettingsByTenantID(
    tenantID: string
  ): Promise<FindMspTenantSettingsQueryResult["settings"]> {
    try {
      const result = (await this._sender.send(
        `/msp-tenant-settings?tenantID=${tenantID}`
      )) as FindMspTenantSettingsQueryResult;

      return result.settings;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_MSP_TENANT_SETTINGS
      );
    }
  }

  public async getReallocationJobsByTenantID(
    tenantID: string
  ): Promise<FindReallocationJobsQueryResult["reallocationJobs"]> {
    try {
      const result = (await this._sender.send(
        `/reallocation-jobs?tenantID=${tenantID}`
      )) as FindReallocationJobsQueryResult;

      return result.reallocationJobs;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_REALLOCATION_JOBS
      );
    }
  }

  public async getReallocationsByTenantID(
    tenantID: string
  ): Promise<FindReallocationsQueryResult["reallocations"]> {
    try {
      const result = (await this._sender.send(
        `/reallocations?tenantID=${tenantID}`
      )) as FindReallocationsQueryResult;

      return result.reallocations;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_REALLOCATIONS
      );
    }
  }

  public async getRampPlansByTenantID(
    tenantID: string
  ): Promise<FindRampPlansQueryResult["rampPlans"]> {
    try {
      const result = (await this._sender.send(
        `/ramp-plans?tenantID=${tenantID}`
      )) as FindRampPlansQueryResult;

      // Removing defunct breakpoints before returning
      result.rampPlans.forEach((rampPlan) => {
        rampPlan.breakpoints = rampPlan.breakpoints.filter(
          (breakpoint) => !breakpoint.defunct
        );
      });

      return result.rampPlans;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_RAMP_PLANS
      );
    }
  }

  public async getRecommendationsByTenantID(
    tenantID: string,
    params: GetRecommendationsParams
  ): Promise<FindRecommendationsQueryResult["recommendations"]> {
    try {
      const searchParams = new URLSearchParams({
        tenantID,
        kind: "recommendation",
      });

      if (params.category) {
        searchParams.append("category", params.category);
      }

      if (params.cloudProviderType) {
        searchParams.append("provider", params.cloudProviderType);
      }

      if (params.serviceType) {
        searchParams.append("serviceType", params.serviceType);
      }

      const result = (await this._sender.send(
        `/recommendations?${searchParams.toString()}`
      )) as FindRecommendationsQueryResult;

      return result.recommendations;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_RECOMMENDATIONS
      );
    }
  }

  public async getRecommendationByID(
    recommendationID: string
  ): Promise<FindRecommendationQueryResult["recommendation"]> {
    try {
      const result = (await this._sender.send(
        `/recommendations/${recommendationID}`
      )) as FindRecommendationQueryResult;

      return result.recommendation;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_RECOMMENDATION
      );
    }
  }

  public async getRecommendationTypes(): Promise<
    FindRecommendationTypesQueryResult["recommendationTypes"]
  > {
    try {
      const result = (await this._sender.send(
        "/recommendation-types"
      )) as FindRecommendationTypesQueryResult;

      return result.recommendationTypes;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_RECOMMENDATION_TYPES
      );
    }
  }

  public async getRecommendationTypeByID(
    recommendationTypeID: string
  ): Promise<FindRecommendationTypeQueryResult["recommendationType"]> {
    try {
      const result = (await this._sender.send(
        `/recommendation-types/${recommendationTypeID}`
      )) as FindRecommendationTypeQueryResult;

      return result.recommendationType;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_RECOMMENDATION_TYPE
      );
    }
  }

  public async getReportByID(
    reportID: string,
    tenantID: string
  ): Promise<FindReportQueryResult["report"]> {
    try {
      const result = (await this._sender.send(
        `/reports/${reportID}?tenantID=${tenantID}`
      )) as FindReportQueryResult;

      return result.report;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_LOADING_REPORT);
    }
  }

  public async getReportsByTenantID(
    tenantID: string
  ): Promise<FindReportsQueryResult["reports"]> {
    try {
      const result = (await this._sender.send(
        `/reports?tenantID=${tenantID}`
      )) as FindReportsQueryResult;

      return result.reports;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_LOADING_REPORTS);
    }
  }

  public async getReportsByIDs(
    reportIDs: string[]
  ): Promise<FindReportsQueryResult["reports"]> {
    try {
      if (reportIDs.length === 0) return [];

      const reportIDChunks = chunk(reportIDs, 40);

      const results = (await Promise.all(
        reportIDChunks.map((reportIDs) => {
          const searchParams = new URLSearchParams(
            reportIDs.map((reportID) => ["reportIDs[]", reportID])
          );

          return this._sender.send(`reports?${searchParams.toString()}`);
        })
      )) as FindReportsQueryResult[];

      return results.map((result) => result.reports).flat();
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_LOADING_REPORTS);
    }
  }

  public async getRolesByTenantID(
    tenantID: string
  ): Promise<FindRolesQueryResult["roles"]> {
    try {
      const result = (await this._sender.send(
        `/roles?tenantID=${tenantID}`
      )) as FindRolesQueryResult;

      return result.roles;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_LOADING_ROLES);
    }
  }

  public async getRuleSetsByTenantID(
    tenantID: string
  ): Promise<FindMspBillingRuleSetsQueryResult["ruleSets"]> {
    try {
      const result = (await this._sender.send(
        `/msp-rule-sets?tenantID=${tenantID}`
      )) as FindMspBillingRuleSetsQueryResult;

      return result.ruleSets;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_LOADING_RULE_SETS);
    }
  }

  public async getSavingsOpportunityFiltersByTenantID(
    tenantID: string
  ): Promise<FindSavingsOpportunityFiltersQueryResult["filters"]> {
    try {
      const result = (await this._sender.send(
        `/savings-opportunity-filter?tenantID=${tenantID}`
      )) as FindSavingsOpportunityFiltersQueryResult;

      return result.filters;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_SCOPED_VIEWS
      );
    }
  }

  public async getScopedViewFiltersByIDs(
    scopedViewIDs: string[]
  ): Promise<FindScopedViewFiltersQueryResult["filters"]> {
    try {
      if (scopedViewIDs.length === 0) return [];

      const scopedViewIDChunks = chunk(scopedViewIDs, 40);

      const results = await Promise.all(
        scopedViewIDChunks.map((scopedViewIDs) => {
          const searchParams = new URLSearchParams(
            scopedViewIDs.map((scopedViewIDs) => [
              "scopedViewIDs[]",
              scopedViewIDs,
            ])
          );

          const result = this._sender.send(
            `scoped-views/filters?${searchParams.toString()}`
          ) as Promise<FindScopedViewFiltersQueryResult>;

          return result;
        })
      );

      return results.map((result) => result.filters).flat();
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_SCOPED_VIEW_FILTERS
      );
    }
  }

  public async getScopedViewsByTenantID(
    tenantID: string
  ): Promise<FindScopedViewsQueryResult["scopedViews"]> {
    try {
      const result = (await this._sender.send(
        `/scoped-views?tenantID=${tenantID}`
      )) as FindScopedViewsQueryResult;

      return result.scopedViews;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_SCOPED_VIEWS
      );
    }
  }

  public async getSlackIntegrationByTenantID(
    tenantID: string
  ): Promise<FindSlackIntegrationQueryResult["integration"]> {
    try {
      const result = (await this._sender.send(
        `/integrations/slack?tenantID=${tenantID}`
      )) as FindSlackIntegrationQueryResult;

      return result.integration;
    } catch (error) {
      let reason;

      if (
        error instanceof Error &&
        FindSlackIntegrationQueryFailure.REASON_INTEGRATION_NOT_FOUND.includes(
          error.message
        )
      ) {
        reason = "NOT_FOUND";
      } else {
        this._eventReporter.reportError(error);
      }

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_SLACK_INTEGRATION,
        { reason }
      );
    }
  }

  public async getTenantByID(
    tenantID: string
  ): Promise<FindTenantQueryResult["tenant"]> {
    try {
      const result = (await this._sender.send(
        `/tenants/${tenantID}`
      )) as FindTenantQueryResult;

      return result.tenant;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_LOADING_TENANT);
    }
  }

  public async getTenants(): Promise<FindTenantsQueryResult["tenants"]> {
    try {
      const result = (await this._sender.send(
        `/tenants`
      )) as FindTenantsQueryResult;

      return result.tenants;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_LOADING_TENANTS);
    }
  }

  public async getTenantsByParentTenantID(
    parentTenantID: string
  ): Promise<FindTenantsQueryResult["tenants"]> {
    try {
      const result = (await this._sender.send(
        `/tenants?parentTenantID=${parentTenantID}`
      )) as FindTenantsQueryResult;

      return result.tenants;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_LOADING_TENANTS);
    }
  }

  public async getUsersByIDs(
    userIDs: string[]
  ): Promise<FindUsersQueryResult["users"]> {
    try {
      if (userIDs.length === 0) return [];

      const userIDChunks = chunk(userIDs, 40);

      const results = (await Promise.all(
        userIDChunks.map((userIDs) => {
          const searchParams = new URLSearchParams(
            userIDs.map((userID) => ["userIDs", userID])
          );

          return this._sender.send(`users?${searchParams.toString()}`);
        })
      )) as FindUsersQueryResult[];

      return results.map((result) => result.users).flat();
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_LOADING_USERS);
    }
  }

  public async getUsersByTenantID(
    tenantID: string
  ): Promise<FindUsersQueryResult["users"]> {
    try {
      const result = (await this._sender.send(
        `users?tenantID=${tenantID}`
      )) as FindUsersQueryResult;

      return result.users;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_LOADING_USERS);
    }
  }

  public async getUserGroupConfigsByTenantID(
    tenantID: string
  ): Promise<FindUserGroupConfigsQueryResult["userGroupConfigs"]> {
    try {
      const result = (await this._sender.send(
        `user-group-configs?tenantID=${tenantID}`
      )) as FindUserGroupConfigsQueryResult;

      return result.userGroupConfigs;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_LOADING_USER_GROUP_CONFIGS
      );
    }
  }

  public async grantUsersMspAccess(
    params: GrantUsersMspAccessParams
  ): Promise<string[]> {
    try {
      const result = (await this._sender.send("/users/grant-msp-access", {
        method: "POST",
        body: params,
      })) as GrantUsersGlobalAccessCommandResult;

      return result.userIDs;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_GRANTING_USERS_MSP_ACCESS
      );
    }
  }

  public async grantUsersTenantAccess(
    params: GrantUsersTenantAccessParams
  ): Promise<string[]> {
    try {
      const result = (await this._sender.send("/users/grant-tenant-access", {
        method: "POST",
        body: params,
      })) as GrantUsersTenantAccessCommandResult;

      return result.userIDs;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_GRANTING_USERS_TENANT_ACCESS
      );
    }
  }

  public async removeFeatureFlag(
    params: RemoveFeatureFlagParameters
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/feature-flags`, {
        method: "DELETE",
        body: params,
      })) as RemoveFeatureFlagCommandResult;

      return result.flag;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_REMOVING_FEATURE_FLAG
      );
    }
  }

  public async revokeUserMspAccess(
    params: RevokeUserMspAccessParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/users/revoke-msp-access`, {
        method: "POST",
        body: params,
      })) as RevokeUserGlobalAccessCommandResult;

      return result.userID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_REVOKING_USER_MSP_ACCESS
      );
    }
  }

  public async revokeUserTenantAccess(
    params: RevokeUserTenantAccessParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/users/revoke-tenant-access`, {
        method: "POST",
        body: params,
      })) as RevokeUserTenantAccessCommandResult;

      return result.userID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_REVOKING_USER_TENANT_ACCESS
      );
    }
  }

  public async triggerReallocation(reallocationID: string): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/reallocations/${reallocationID}/trigger-reallocation`,
        { method: "POST" }
      )) as TriggerReallocationCommandResult;

      return result.reallocationID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_TRIGGERING_REALLOCATION
      );
    }
  }

  public async updateAlertRule(
    alertRuleID: string,
    params: UpdateAlertRuleParameters
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/alert-rules/${alertRuleID}`, {
        method: "PATCH",
        body: params,
      })) as UpdateAlertRuleCommandResult;

      return result.ruleID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_ALERT_RULE
      );
    }
  }

  public async updateAlibabaIntegration(
    integrationID: string,
    params: UpdateAlibabaIntegrationParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/data-integrations/alibaba/${integrationID}`,
        { method: "PATCH", body: params }
      )) as UpdateAlibabaIntegrationCommandResult;

      return result.integrationID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_ALIBABA_INTEGRATION
      );
    }
  }

  public async updateAWSIntegration(
    integrationID: string,
    params: UpdateAWSIntegrationParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/data-integrations/aws/${integrationID}`,
        { method: "PATCH", body: params }
      )) as UpdateAWSIntegrationCommandResult;

      return result.integrationID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_AWS_INTEGRATION
      );
    }
  }

  public async updateAzureIntegration(
    integrationID: string,
    params: UpdateAzureIntegrationParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/data-integrations/azure/${integrationID}`,
        { method: "PATCH", body: params }
      )) as UpdateAzureIntegrationCommandResult;

      return result.integrationID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_AZURE_INTEGRATION
      );
    }
  }

  public async updateBudget(
    budgetID: string,
    params: UpdateBudgetParameters
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/budgets/${budgetID}`, {
        method: "PATCH",
        body: params,
      })) as UpdateBudgetCommandResult;

      return result.budgetID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_UPDATING_BUDGET);
    }
  }

  public async updateCases(
    params: UpdateCasesParams
  ): Promise<UpdateCasesCommandResult["caseIDs"]> {
    try {
      const result = (await this._sender.send(`/cases`, {
        method: "PATCH",
        body: params,
      })) as UpdateCasesCommandResult;

      return result.caseIDs;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_UPDATING_CASES);
    }
  }

  public async updateCase(
    caseID: string,
    params: UpdateCaseParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/cases/${caseID}`, {
        method: "PATCH",
        body: params,
      })) as UpdateCaseCommandResult;

      return result.caseID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_UPDATING_CASE);
    }
  }

  public async updateCustomLabel(
    customLabelID: string,
    params: UpdateCustomLabelParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/custom-labels/${customLabelID}`,
        { method: "PATCH", body: params }
      )) as UpdateCustomLabelCommandResult;

      return result.customLabelID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_CUSTOM_LABEL
      );
    }
  }

  public async updateCustomMetric(
    customMetricID: string,
    params: UpdateCustomMetricParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/custom-metrics/${customMetricID}`,
        { method: "PATCH", body: params }
      )) as UpdateCustomMetricCommandResult;

      return result.customMetricID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_CUSTOM_METRIC
      );
    }
  }

  public async updateDashboard(
    dashboardID: string,
    params: UpdateDashboardParameters
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/dashboards/${dashboardID}`, {
        method: "PATCH",
        body: params,
      })) as UpdateDashboardCommandResult;

      return result.dashboardID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_DASHBOARD
      );
    }
  }

  public async updateDashboards(
    tenantID: string,
    params: UpdateDashboardsParameters
  ): Promise<string[]> {
    try {
      const result = (await this._sender.send(`/dashboards`, {
        method: "PATCH",
        body: { ...params, tenantID },
      })) as UpdateDashboardsCommandResult;

      return result.dashboardIDs;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_DASHBOARD
      );
    }
  }

  public async updateGCPIntegration(
    integrationID: string,
    params: UpdateGCPIntegrationParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/data-integrations/gcp/${integrationID}`,
        { method: "PATCH", body: params }
      )) as UpdateGCPIntegrationCommandResult;

      return result.integrationID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_GCP_INTEGRATION
      );
    }
  }

  public async updateJiraIntegration(
    params: UpdateJiraIntegrationParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/integrations/jira`, {
        method: "PATCH",
        body: params,
      })) as UpdateJiraIntegrationCommandResult;

      return result.integrationID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_JIRA_INTEGRATION
      );
    }
  }

  public async updateLabelGroupingRule(
    labelGroupingRuleID: string,
    params: UpdateLabelGroupingRuleParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/label-grouping-rules/${labelGroupingRuleID}`,
        { method: "PATCH", body: params }
      )) as UpdateLabelGroupingRuleCommandResult;

      return result.labelGroupingRuleID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_LABEL_GROUPING_RULE
      );
    }
  }

  public async updateMspBillingInfo(
    billingInfoID: string,
    params: UpdateMspBillingInfoParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/msp-billing-info/${billingInfoID}`,
        { method: "PATCH", body: params }
      )) as UpdateMspBillingInfoCommandResult;

      return result.billingInfoID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_MSP_BILLING_INFORMATION
      );
    }
  }

  public async updateMspBillingInvoice(
    invoiceSettingsID: string,
    params: UpdateMspBillingInvoiceParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/msp-billing-invoice/${invoiceSettingsID}`,
        { method: "PATCH", body: params }
      )) as UpdateMspBillingInvoiceCommandResult;

      return result.invoiceID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_MSP_BILLING_INVOICE
      );
    }
  }

  public async updateMspSharedPayerIntegrations(
    params: UpdateMspSharedPayerIntegrationsParams
  ): Promise<string[]> {
    try {
      const result = (await this._sender.send(
        `/msp-shared-payer-data-integrations`,
        {
          method: "PATCH",
          body: params,
        }
      )) as UpdateMspSharedPayerIntegrationsCommandResult;

      return result.integrationIDs;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_MSP_SHARED_PAYER_INTEGRATIONS
      );
    }
  }

  public async updateMspTenantSettings(
    settingsID: string,
    params: UpdateMspTenantSettingsParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/msp-tenant-settings/${settingsID}`,
        { method: "PATCH", body: params }
      )) as UpdateMspTenantSettingsCommandResult;

      return result.settingsID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_MSP_TENANT_SETTINGS
      );
    }
  }

  public async updateSlackIntegration(
    params: UpdateSlackIntegrationParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/integrations/slack`, {
        method: "PATCH",
        body: params,
      })) as UpdateSlackIntegrationCommandResult;

      return result.integrationID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_SLACK_INTEGRATION
      );
    }
  }

  public async updateOracleIntegration(
    integrationID: string,
    params: UpdateOracleIntegrationParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/data-integrations/oracle/${integrationID}`,
        { method: "PATCH", body: params }
      )) as UpdateOracleIntegrationCommandResult;

      return result.integrationID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_ORACLE_INTEGRATION
      );
    }
  }

  public async updateDimensionPreferences(
    params: UpdateDimensionPreferencesParams
  ): Promise<UpdateDimensionPreferencesCommandResult["preferences"]> {
    try {
      const result = (await this._sender.send(`/dimension-preferences`, {
        method: "PATCH",
        body: params,
      })) as UpdateDimensionPreferencesCommandResult;

      return result.preferences;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_LABEL_PREFERENCES
      );
    }
  }

  public async updateMeasurePreferences(
    params: UpdateMeasurePreferencesParams
  ): Promise<UpdateMeasurePreferencesCommandResult["preferences"]> {
    try {
      const result = (await this._sender.send(`/measure-preferences`, {
        method: "PATCH",
        body: params,
      })) as UpdateMeasurePreferencesCommandResult;

      return result.preferences;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_MEASURE_PREFERENCES
      );
    }
  }

  public async updateRampPlan(params: UpdateRampPlanParams): Promise<string> {
    try {
      const body = {
        ...params,
        ...(params.enumeratedValues
          ? {
              key:
                params.enumeratedValues.length > 0
                  ? "ternary.app/category"
                  : "",
            }
          : {}),
      };

      const result = (await this._sender.send(
        `/ramp-plans/${params.rampPlanID}`,
        { method: "PATCH", body }
      )) as UpdateRampPlanCommandResult;

      return result.rampPlanID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_RAMP_PLAN
      );
    }
  }

  public async updateReallocation(
    reallocationID: string,
    params: UpdateReallocationParameters
  ): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/reallocations/${reallocationID}`,
        { method: "PATCH", body: params }
      )) as UpdateReallocationCommandResult;

      return result.reallocationID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_REALLOCATION
      );
    }
  }

  public async updateRecommendation(
    recommendationID: string,
    params: UpdateRecommendationParameters
  ): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/recommendations/${recommendationID}`,
        { method: "PATCH", body: params }
      )) as UpdateRecommendationCommandResult;

      return result.recommendationID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_RECOMMENDATION
      );
    }
  }

  public async updateRecommendations(
    params: UpdateRecommendationsParameters
  ): Promise<string[]> {
    try {
      const result = (await this._sender.send(`/recommendations`, {
        method: "PATCH",
        body: params,
      })) as UpdateRecommendationsCommandResult;

      return result.recommendationIDs;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_RECOMMENDATIONS
      );
    }
  }

  public async updateReport(
    reportID: string,
    params: UpdateReportParameters
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/reports/${reportID}`, {
        method: "PATCH",
        body: params,
      })) as UpdateReportCommandResult;

      return result.reportID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_UPDATING_REPORT);
    }
  }

  public async updateResourceSubscription(
    subscriptionID: string,
    params: UpdateResourceSubscriptionParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/resource-subscriptions/${subscriptionID}`,
        {
          method: "PATCH",
          body: params,
        }
      )) as UpdateResourceSubscriptionCommandResult;

      return result.subscriptionID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_REPORT_SUBSCRIPTION
      );
    }
  }

  public async updateReports(
    params: UpdateReportsParameters
  ): Promise<string[]> {
    try {
      const result = (await this._sender.send(`/reports`, {
        method: "PATCH",
        body: params,
      })) as UpdateReportsCommandResult;

      return result.reportIDs;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_UPDATING_REPORTS);
    }
  }

  public async updateRuleSet(
    ruleSetID: string,
    params: UpdateRuleSetParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/msp-rule-sets/${ruleSetID}`, {
        method: "PATCH",
        body: params,
      })) as UpdateMspBillingRuleSetCommandResult;

      return result.ruleSetID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_UPDATING_RULE_SET);
    }
  }

  public async updateSavingsOpportunityFilter(
    filterID: string,
    params: UpdateSavingsOpportunityFilterParameters
  ): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/savings-opportunity-filter/${filterID}`,
        { method: "PATCH", body: params }
      )) as UpdateSavingsOpportunityFilterCommandResult;

      return result.filterID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_SAVING_OPPORTUNITY_FILTER
      );
    }
  }

  public async updateScopedView(
    scopedViewID: string,
    params: UpdateScopedViewParameters
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/scoped-views/${scopedViewID}`, {
        method: "PATCH",
        body: params,
      })) as UpdateScopedViewCommandResult;

      return result.scopedViewID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_SCOPED_VIEW
      );
    }
  }

  public async updateSnowflakeIntegration(
    integrationID: string,
    params: UpdateSnowflakeIntegrationParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/data-integrations/snowflake/${integrationID}`,
        { method: "PATCH", body: params }
      )) as UpdateSnowflakeIntegrationCommandResult;

      return result.integrationID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_SNOWFLAKE_INTEGRATION
      );
    }
  }

  public async updateTenant(
    tenantID: string,
    params: UpdateTenantParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/tenants/${tenantID}`, {
        method: "PATCH",
        body: params,
      })) as UpdateTenantCommandResult;

      return result.tenantID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_UPDATING_TENANT);
    }
  }

  public async updateUser(
    userID: string,
    params: UpdateUserParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/users/${userID}`, {
        method: "PATCH",
        body: params,
      })) as UpdateUserCommandResult;

      return result.userID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_UPDATING_USER);
    }
  }

  public async updateUserGroupConfig(
    userGroupConfigID: string,
    params: UpdateUserGroupConfigParameters
  ): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/user-group-configs/${userGroupConfigID}`,
        { method: "PATCH", body: params }
      )) as UpdateUserGroupConfigCommandResult;

      return result.userGroupConfigID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_USER_GROUP_CONFIG
      );
    }
  }

  public async updateUserTenantRoles(
    grantID: string,
    params: UpdateUserTenantRolesParams
  ): Promise<string> {
    try {
      const result = (await this._sender.send(`/grants/${grantID}`, {
        method: "PATCH",
        body: params,
      })) as UpdateUserTenantRolesCommandResult;

      return result.grantID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_USER_TENANT_PERMISSIONS
      );
    }
  }

  public async updateUserTenantSettings(
    params: UpdateUserTenantSettingParams
  ): Promise<UpdateUserTenantSettingsCommandResult> {
    try {
      const result = (await this._sender.send(`/user-settings`, {
        method: "PATCH",
        body: params,
      })) as UpdateUserTenantSettingsCommandResult;

      return result;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_UPDATING_USER_SETTINGS
      );
    }
  }

  public async uploadImage(params: UploadImageParams): Promise<string> {
    const formData = new FormData();

    formData.append("tenantID", params.tenantID);
    formData.append("file", params.file);

    try {
      const result = (await this._sender.send(`/file`, {
        method: "POST",
        body: formData,
      })) as UploadImageCommandResult;

      return result.fileID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(CoreAPIClientError.FAILED_UPLOADING_FILE);
    }
  }

  public async validateDataIntegration(
    integrationID: string
  ): Promise<ValidateDataIntegrationCommandResult["validations"]> {
    try {
      const result = (await this._sender.send(`/data-integrations/validate`, {
        method: "POST",
        body: { integrationID },
      })) as ValidateDataIntegrationCommandResult;

      return result.validations;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new CoreAPIClientError(
        CoreAPIClientError.FAILED_VALIDATING_DATA_INTEGRATION
      );
    }
  }
}
